import React, { useState, useContext } from 'react'
import { Layout } from './Layout'
import { Store } from '../../state/Provider'

const useToggle = () => {
  const [open, setOpen] = useState(false)

  const toggle = (shouldOpen: boolean) => () => setOpen(shouldOpen)

  return { open, toggle }
}

interface Props {
  title?: string
  testId?: string
  showBanner?: boolean
}

export const LayoutWrapper: React.SFC<Props> = ({
  title,
  children,
  testId,
  showBanner = false
}) => {
  const { open: drawerOpen, toggle: toggleDrawer } = useToggle()
  const [authenticated] = useState(
    localStorage.getItem('authenticated') === 'true'
  )

  const { state } = useContext(Store)
  const [clinicId] = useState(state.clinicId)

  return (
    <Layout
      drawerOpen={drawerOpen}
      openDrawer={toggleDrawer(true)}
      closeDrawer={toggleDrawer(false)}
      title={title}
      clinicId={clinicId}
      authenticated={authenticated}
      testId={testId}
      showBanner={showBanner}
    >
      {children}
    </Layout>
  )
}
