import { defaultsDeep } from 'lodash'
import { getCookieValue } from '../browser/getCookieValue'

export function jsonRequest(path: string, options: RequestInit) {
  const csrfToken = getCookieValue('X-CSRF-Token')
  const pxUrl = process.env.REACT_APP_PX_URI || ''
  return fetch(
    pxUrl + path,
    defaultsDeep(options, {
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      }
    })
  )
}
