import React, { useContext } from 'react'
import { Layout } from './Layout'
import { LoadContentTicket } from '../../dataProviders/LoadContentTicket'
// eslint-disable-next-line import/named
import { Store } from '../../state/Provider'
import { getQueryParam } from '../../browser/getQueryParam'
import { setClinics } from '../../state/setClinic'
import { MatchIdProps } from '../../types/index'
import { Redirect } from 'react-router-dom'

export const ViewMessage: React.FC<MatchIdProps> = ({
  match: {
    params: { id }
  }
}) => {
  const { dispatch } = useContext(Store)
  const termsAccepted = getQueryParam('termsAccepted')
  const consentAccepted = getQueryParam('consentAccepted')
  const welcomeMessage = getQueryParam('welcome')

  return (
    <LoadContentTicket
      contentTicketId={id}
      render={contentTicket => {
        const welcome = termsAccepted === 'true' || welcomeMessage === 'true'
        setClinics(dispatch, contentTicket.clinic)

        if(contentTicket.service === 'patient_education' && consentAccepted !== 'true') {
          return <Redirect to={`/patient-consent/${id}`} />
        }

        return (
          <Layout
            instruction={contentTicket.content}
            welcome={welcome}
            clinicName={contentTicket.clinic[0].name}
          />
        )
      }}
    />
  )
}
