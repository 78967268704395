import React, { Dispatch, SetStateAction, useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { Layout } from './Layout'
import { getQueryParam } from '../../browser/getQueryParam'
import { jsonRequest } from '../../api/jsonRequest'
import { Store } from '../../state/Provider'
import { Context as AnalyticsContext } from '../../analytics'
import { UrlLoader } from '../../components/UrlLoader'

const acceptTerms = (
  setTermsAccepted: Dispatch<SetStateAction<boolean>>
) => async () => {
  await jsonRequest('/api/terms_and_conditions_response?commit=Continue', {
    method: 'POST',
    credentials: 'include'
  })

  return setTermsAccepted(true)
}

const declineTerms = (
  setTermsDeclined: Dispatch<SetStateAction<boolean>>
) => () => {
  setTermsDeclined(true)
}

export const AcceptTerms: React.SFC<{}> = () => {
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [termsDeclined, setTermsDeclined] = useState(false)
  const [ticketId] = useState(getQueryParam('urlToken'))
  const analytics = useContext(AnalyticsContext)
  const {
    state: { clinicName }
  } = useContext(Store)

  if (termsAccepted) {
    analytics.event('terms_accepted')
    return <UrlLoader urlToken={ticketId || ''} />
  }

  if (termsDeclined) {
    analytics.event('terms_declined')
    return (
      <Redirect
        to={{ pathname: `/terms-declined/${ticketId || ''}`, search: window.location.search }}
      />
    )
  }

  return (
    <Layout
      acceptTerms={acceptTerms(setTermsAccepted)}
      declineTerms={declineTerms(setTermsDeclined)}
      clinicName={clinicName}
    />
  )
}
