import React from 'react'
import { Action } from './reducers'

export function setService(
  dispatch: React.Dispatch<Action>,
  service: string
) {
  dispatch({ type: 'SET_SERVICE', payload: service })

  sessionStorage.setItem('service', service)
}
