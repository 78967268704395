import React, { useContext, useLayoutEffect } from 'react'
import { LoadPath, LoadProps } from './LoaderFactory'
import { Preview } from '../types/models'
import { Store } from '../state/Provider'
import { setClinics } from '../state/setClinic'
import { setService } from '../state/setService'

interface Props<M> extends LoadProps<M> {
  urlToken: string
  render: () => React.ReactNode
}

const getPath = (urlToken: string) => `/api/url_resources/${urlToken}/preview`

const PreviewWrapper: React.FC<{
  preview: Preview
  render: () => React.ReactNode
}> = ({ preview, render }) => {
  const { dispatch } = useContext(Store)

  // firing a layout effect here because setting the service can trigger layout changes
  useLayoutEffect(() => {
    setClinics(dispatch, preview.clinic)
    setService(dispatch, preview.service)
  })

  return <>{render()}</>
}

export const LoadPreview: React.FC<Props<Preview>> = ({ urlToken, render }) => {
  const { state } = useContext(Store)

  // prevent rendering LoadPath if state is already set to avoid calling preview API twice
  if (state.service && state.clinicName) {
    return <>{render()}</>
  }

  const wrappedRender = (preview: Preview) => (
    <PreviewWrapper preview={preview} render={render} />
  )

  return (
    <LoadPath<Preview>
      path={getPath(urlToken)}
      urlToken={urlToken}
      render={wrappedRender}
    />
  )
}
