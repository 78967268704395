import { FixedVariant } from '../../styles/types'
import styled from '@emotion/styled'
import { variant } from 'styled-system'
import { Flex, FlexProps } from './Flex'

export type FixedProps = FlexProps & {
  variant: FixedVariant
}

const fixedStyle = variant({ key: 'fixed' })

export const Fixed: React.FC<FixedProps> = styled(Flex)(fixedStyle)
Fixed.displayName = 'Fixed'
