import React, { useReducer } from 'react'
import { reducer, State, Action } from './reducers'

// eslint-disable-next-line import/no-mutable-exports
export let Store: React.Context<{
  state: State
  dispatch: React.Dispatch<Action>
}>

export const Provider: React.FC = ({ children }) => {
  const initialState: State = {
    clinicId: localStorage.getItem('clinicId') || undefined,
    clinicName: localStorage.getItem('clinicName') || undefined,
    clinicPhoneNumber: localStorage.getItem('clinicPhoneNumber') || undefined,
    service: sessionStorage.getItem('service') as string || undefined,
    urlToken: sessionStorage.getItem('urlToken') as string || undefined 
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  const store = { state, dispatch }

  Store = React.createContext(store)

  return <Store.Provider value={store}>{children}</Store.Provider>
}
