import React, { useContext, useEffect } from 'react'
import { Store } from '../../state/Provider'
import { Layout } from './Layout'
import { jsonRequest } from '../../api/jsonRequest'
import { MatchUrlTokenProps } from '../../types'

export const TermsDeclined: React.SFC<MatchUrlTokenProps> = ({
  match: {
    params: { urlToken }
  }
}) => {
  useEffect(() => {
    jsonRequest('/api/sessions/1/truncate', { method: 'PUT' })
  }, [])

  const {
    state: { clinicName }
  } = useContext(Store)

  return <Layout clinicName={clinicName} urlToken={urlToken} />
}