import {InputStyles} from "../../types";
import {space, colors, fonts, fontSizes, lineHeights} from "./scalar";

export const inputs: InputStyles = {
  text: {
    padding: `${space[1]}px`,
    marginBottom: `${space[3]}px`,
    lineHeight: `${lineHeights[1]}`,
    fontFamily: fonts.sans,
    fontSize: fontSizes.normal,
    color: colors.black,
    backgroundColor: colors.white,
    border: '1px solid',
    borderColor: colors.lightGrey,
    label: 'TextInput'
  }
}