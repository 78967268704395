import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from './Library/Typography'
import { Flex } from './Library/Flex'
import { LayoutWrapper } from '../layouts/LayoutWrapper'
import { ReactComponent as ErrorIcon } from '../svg/icon-error-http.svg'

interface Props {
  code: number
}

export const ErrorMessage: React.FC<Props> = ({ code }) => {
  const { t } = useTranslation()
  return (
    <LayoutWrapper>
      <Flex width="100%" alignItems="center" flexDirection="column" py="1px">
        <ErrorIcon width="60" height="60" />
      </Flex>
      <Flex
        width="100%"
        alignItems="center"
        flexDirection="column"
        py={{ medium: '10px', large: '24px' }}
      >
        <Text textAlign="center" textStyle="heading">
          {t('errorMessage.title')}
        </Text>
      </Flex>
      <Flex
        width="100%"
        alignItems="center"
        flexDirection="column"
        py={{ medium: '4px', large: '0px' }}
      >
        <Text textAlign="center" textStyle="normal">
          {t('errorMessage.error')}
        </Text>
      </Flex>
      <Flex
        width="100%"
        alignItems="center"
        flexDirection="column"
        py={{ medium: '10px', large: '17px' }}
      >
        <Text
          textAlign="center"
          textStyle="darkGrey"
          height={{ medium: '20px', large: '22px' }}
        >
          {t('errorMessage.errorCode', { code })}
        </Text>
      </Flex>
    </LayoutWrapper>
  )
}
