import React, {useContext} from 'react'
import {ThemeProvider as EmotionThemeProvider}  from 'emotion-theming'
import {Global} from "@emotion/core";
import {AppTheme} from "../types";
import {Store} from "../../state/Provider";
import {getThemeById, getThemeIdForService, ThemeId} from "./index";

/**
 * Wraps emotion's theme provider with context support in order to inject global styles
 * and support updating the theme deeper in the node tree.
 * @param initialTheme
 * @param children
 * @constructor
 */
export const ThemeProvider = ({ themeId, children }: {themeId?: ThemeId, children: React.ReactNode }) => {
  const { state } = useContext(Store)

  let theme: AppTheme
  if (themeId) {
    theme = getThemeById(themeId)
  } else if (state.service) {
    theme = getThemeById(getThemeIdForService(state.service))
  } else {
    theme = getThemeById('default')
  }
  return (
    <EmotionThemeProvider theme={theme}>
      <Global styles={theme.globalStyles} />
      {children}
    </EmotionThemeProvider>
  )
}