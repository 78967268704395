import React from 'react'
import { LoadPath, LoadProps } from './LoaderFactory'
import { PatientConsent } from '../types/models'

const PATIENT_CONSENT_ENDPOINT = '/api/patient-consent'

export const LoadPatientConsent: React.SFC<LoadProps<PatientConsent>> = ({
  render
}) => (
  <LoadPath<PatientConsent> path={PATIENT_CONSENT_ENDPOINT} render={render} />
)
