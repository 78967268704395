import React from 'react'
import styled from '@emotion/styled'
import { TextVariant } from '../../styles/types'
import {
  fontFamily,
  FontFamilyProps,
  fontWeight,
  FontWeightProps,
  letterSpacing,
  LetterSpacingProps,
  lineHeight,
  LineHeightProps,
  textStyle,
  TextStyleProps
} from 'styled-system'
import { Box, BoxProps } from './Box'

export type PProps = BoxProps &
  FontFamilyProps &
  FontWeightProps &
  LetterSpacingProps &
  LineHeightProps &
  TextStyleProps & {
    textStyle?: TextVariant
  }

export const P: React.FC<PProps> = styled(Box)(
  fontFamily,
  fontWeight,
  letterSpacing,
  lineHeight,
  textStyle
)
P.displayName = 'P'
