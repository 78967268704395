import React from 'react'
import { useTranslation } from 'react-i18next'
import { Label, TextInput } from './Library'

export interface ZipCodeProps {
  setValidationError: (n: string) => void
  setZipcode: (n: string) => void
  validationError: string
  zipcode: string
}

export const ZipCode: React.FC<ZipCodeProps> = ({
  setValidationError,
  setZipcode,
  validationError,
  zipcode
}) => {
  const { t } = useTranslation()
  const validationMessage = t('verify.validationMessage')
  const onBlur = (zipcodeInput: string): void => {
    if (!validationError || validationError === validationMessage) {
      if (zipcodeInput.length !== 5) {
        setValidationError(validationMessage)
      } else {
        setValidationError('')
      }
    }
  }
  const onChange = (zipcodeInput: string): void => {
    if (!validationError || validationError === validationMessage) {
      if (zipcodeInput.length === 5) {
        setValidationError('')
      }
    }
    setZipcode(zipcodeInput)
  }
  return (
    <>
      <Label htmlFor="zipcode">{t('verify.zipCode')}</Label>
      <TextInput
        id="zipcode"
        placeholder="12345"
        aria-label="zipcode"
        minLength={5}
        maxLength={5}
        pattern="[0-9]*"
        size={5}
        value={zipcode}
        onBlur={e => onBlur(e.target.value)}
        onChange={e => onChange(e.target.value)}
      />
    </>
  )
}
