import CSS from "csstype";
import { colors, space } from './scalar'
import { FixedStyles } from '../../types'

export const baseFixedStyle = {
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: colors.wintergreen,
  width: '100%',
  flexShrink: 0,
  zIndex: 1
}

const basePageBanner: CSS.StandardProperties = {
  width: "100%",
  alignItems: "center",
  flexDirection: "column",
  backgroundColor: "transparent",
  color: colors.black
}

const basePageContent: CSS.StandardProperties = {
  flex:"1 0 auto",
  alignItems:"flex-start",
  alignSelf: "center",
  flexDirection: "column",
  margin: '0 auto',
  maxWidth: 'unset',
  width:'100%',
  backgroundColor: "transparent"
}

export const fixed: FixedStyles = {
  footer: {
    ...baseFixedStyle,
    padding: `${space[3]}px 25px`,
    alignItems: 'stretch',
    flex: '0 0 auto',
    flexDirection: 'column',
    backgroundColor: colors.wintergreen,
    label: 'Footer'
  },
  header: {
    ...baseFixedStyle,
    alignItems: "center",
    backgroundColor: colors.wintergreen,
    flex: "0 0 auto",
    padding: `${space[3]}px 25px`,
    label: 'Header'
  },
  errorPageContent: {
    ...basePageContent,
    label: 'ErrorPageContent'
  },
  infoPageContent: {
    ...basePageContent,
    label: 'InfoPageContent'
  },
  formPageContent: {
    ...basePageContent,
    label: 'FormPageContent'
  },
  errorPageBanner: {
    ...basePageBanner,
    label: 'ErrorPageBanner'
  },
  infoPageBanner: {
    ...basePageBanner,
    label: 'InfoPageBanner'
  },
  formPageBanner: {
    ...basePageBanner,
    label: 'FormPageBanner'
  },
  errorBanner: {
    backgroundColor: "#FFDAD3",
    padding: `${space[2]}px`,
    flexDirection: "column",
    borderRadius: '3px',
    label: 'ErrorBanner',
  }
}
