import React, { useContext, useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { Layout } from './Layout'
import { Context as AnalyticsContext } from '../../analytics'
import { MatchIdProps } from '../../types/index'
import { LoadPatientConsent } from '../../dataProviders/LoadPatientConsent'
import { setPageTitle } from '../../utils/setPageTitle'
import { updatePatientConsent, getPatientConsentContents } from './patientConsent'

export const PatientConsent: React.SFC<MatchIdProps> = ({
  match: {
    params: { id }
  }
}) => {
  const [consent, setConsent] = useState(false)
  const [consentDeclined, setConsentDeclined] = useState(false)
  setPageTitle('Patient Consent')
  const analytics = useContext(AnalyticsContext)
  const [consentContent, setConsentContent] = useState({})
  const locale = navigator.language.split('-')[0]

  useEffect(() => {
    getPatientConsentContents(locale).then((response)=> {
      return response.json()
    }).then((response1) => {
        setConsentContent(response1)
      })
  }, [locale])

  const onContinueButtonClick = async () => {
    analytics.event('patient_consent_accepted')
    const consentRes = await updatePatientConsent(true)
    if (consentRes.status === 200) {
      setConsent(true)
    }
  }

  const onDeclineClick = async () => {
    await updatePatientConsent(false)
    setConsent(false)
    setConsentDeclined(true)
  }

  if(consent) {
    setPageTitle('Navigating Care')
    return <Redirect to={`/patient-education/${id}?consentAccepted=true&welcome=true`} />
  }

  if (consentDeclined) {
    setPageTitle('Navigating Care')
    return (
      <Redirect
        to={{ pathname: `/terms-declined/${id}`, search: window.location.search }}
      />
    )
  }

  return (
    <LoadPatientConsent
      render={({ digital_education_activated_at }) => {
        if (digital_education_activated_at) {
          setPageTitle('Navigating Care')
          return <Redirect to={`/patient-education/${id}?consentAccepted=true&welcome=false`} />
        }
        return (
          <Layout
            urlToken={id}
            locale={locale}
            consentContent={consentContent}
            onContinueButtonClick={() => onContinueButtonClick()}
            onDeclineClick={() => onDeclineClick()}
          />
        )
      }}
    />
  )
}
