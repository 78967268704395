import React, { useState, useEffect } from 'react'
import { TextInputProps, TextInput } from '../Library'
import { Omit } from 'lodash'
import { AsYouType } from 'libphonenumber-js'

interface Props extends Omit<TextInputProps, 'onChange'> {
  phone?: string
  onChange: (newDate: string) => void
}

function formatPhone(phone: string) {
  const numbersOnly = phone.replace(/[^0-9]*/g, '')
  if (numbersOnly.length <= 4) {
    return numbersOnly
  }

  return new AsYouType('US').input(numbersOnly)
}

export const PhoneInput: React.FC<Props> = ({ phone, onChange, ...props }) => {
  const formattedPhone = phone ? formatPhone(phone) : phone
  const [value, setValue] = useState(formattedPhone)

  useEffect(() => {
    setValue(formattedPhone)
  }, [phone, formattedPhone])

  const onChangeWrapper = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = formatPhone(e.target.value)

    setValue(newValue)
    onChange(newValue)
  }

  return (
    <TextInput
      id={props.id}
      aria-label={props.id}
      data-testid={props.id}
      type="text"
      placeholder="1-888-555-1234"
      pattern="[0-9]*"
      className={value ? 'notempty' : ''}
      {...props}
      value={value ? value : ''}
      onChange={onChangeWrapper}
    />
  )
}
