import React from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { DateTime } from 'luxon'
import { ContentTicketListItem } from '../../types/models'
import { Box } from '../../components/Library/Box'
import { LayoutWrapper } from '../../layouts/LayoutWrapper'
import { GroupingBox, InternalLink } from '../../components/Library'
import { Text } from '../../components/Library/Typography'

interface Props {
  instructions: ContentTicketListItem[]
}

export const Layout: React.FC<Props> = ({ instructions }) => {
  const { t } = useTranslation()
  return (
    <LayoutWrapper
      testId="instruction-messages"
      title={t('listMessages.title')}
    >
      <GroupingBox>
        {map(
          instructions,
          ticket =>
            ticket.id && (
              <Box
                key={ticket.id}
                pb={2}
                data-testid={`instruction-subject-${ticket.id}`}
              >
                <InternalLink to={`/homecare-instructions/${ticket.id}`}>
                  {ticket.title}
                </InternalLink>
                <Text textStyle="small">
                  {DateTime.fromISO(ticket.createdAt).toLocaleString(
                    DateTime.DATETIME_SHORT
                  )}
                </Text>
              </Box>
            )
        )}
      </GroupingBox>
    </LayoutWrapper>
  )
}
