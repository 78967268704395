import { jsonRequest } from '../../api/jsonRequest'

export async function submitVerification(
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  zipCode: string,
  rememberMe: boolean,
  urlToken: string,
  verifyBirthdateOnly = false
) {
  return jsonRequest(`/api/patient_verification`, {
    method: 'POST',
    body: JSON.stringify({
      patientVerificationForm: {
        firstName,
        lastName,
        dateOfBirth,
        zipCode,
        rememberMe,
        urlToken,
        verifyBirthdateOnly
      }
    })
  })
}
