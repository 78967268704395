import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from '../svg/nc-logo.svg'
import { Box } from './Library/Box'
import { Header } from "./Library";

interface Props {
  column?: boolean
  to?: string
}

export const LogoHeader: React.FC<Props> = ({ children, column, to }) => (
  <Header
    flexDirection={column ? 'column' : 'row'}
  >
    <Box ml={column ? undefined : 'auto'} width={155}>
      {to ? (
        <Link to={to}>
          <Logo />
        </Link>
      ) : (
        <Logo />
      )}
    </Box>
    <Box ml={column ? undefined : 'auto'}>{children}</Box>
  </Header>
)
