import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer } from '@material-ui/core'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { LogoHeader } from '../../components/LogoHeader'
import { MediumHeading } from '../../components/Library/Typography'
import { Content, BorderBottom } from '../../components/Library'
import { CloseIcon } from '../../components/CloseIcon'
import { MenuListItem } from './MenuListItem'
import { Footer } from '../../components/Footer'
import { Icon } from '../../components/Library/Icon'
import { PageContent } from '../../components/PageContent'
import { Store } from '../../state/Provider'

interface Props {
  authenticated: boolean
  title?: string
  clinicId: string | undefined
  drawerOpen: boolean
  openDrawer: () => void
  closeDrawer: () => void
  testId?: string
  showBanner?: boolean
}

export const Layout: React.FC<Props> = ({
  children,
  authenticated,
  title,
  clinicId,
  drawerOpen,
  openDrawer,
  closeDrawer,
  testId,
  showBanner = false
}) => {
  const { t } = useTranslation()
  const { state } = useContext(Store)
  const logoPath = `/verify/${state.urlToken || ''}`

  return (
    <>
      <LogoHeader to={logoPath}>
        {authenticated && (
          <span
            onClick={openDrawer}
            onKeyPress={openDrawer}
            role="button"
            tabIndex={0}
            data-testid="open-drawer"
          >
            <Icon icon={faBars} size="lg" variant="primary" />
          </span>
        )}
      </LogoHeader>

      <PageContent
        pageVariant="info"
        banner={showBanner && { title: title as string }}
      >
        <Content className="layout-content" data-testid={testId} px={25} py={3}>
          {!showBanner && title && (
            <MediumHeading mb={1} alignSelf="center">
              {title}
            </MediumHeading>
          )}

          {children}
        </Content>
      </PageContent>

      <Footer />

      {authenticated && (
        <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
          <Content p={3} width="310px">
            <span
              onClick={closeDrawer}
              onKeyPress={closeDrawer}
              role="button"
              tabIndex={0}
              style={{ alignSelf: 'flex-end' }}
              data-testid="close-drawer"
            >
              <CloseIcon />
            </span>
            <MenuListItem
              title={t('layoutWrapper.messages')}
              to="/homecare-instructions"
              onClick={closeDrawer}
            />
            <BorderBottom width="100%" my={2} />
            <MenuListItem
              title={t('layoutWrapper.yourClinic')}
              to={`/your-clinic/${clinicId}`}
              onClick={closeDrawer}
            />
            <MenuListItem
              title={t('layoutWrapper.preferences')}
              to="/preferences"
              onClick={closeDrawer}
            />
            <MenuListItem
              title={t('layoutWrapper.help')}
              to="/help"
              onClick={closeDrawer}
            />
            <MenuListItem
              title={t('layoutWrapper.signOut')}
              to="/confirm-sign-out"
              onClick={closeDrawer}
            />
          </Content>
        </Drawer>
      )}
    </>
  )
}
