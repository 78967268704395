import {
  colors,
  fonts,
  fontSizes,
  lineHeights,
  fontWeights,
  heights
} from './scalar'
// eslint-disable-next-line import/named
import { ButtonStyles } from '../../types'
import { baseLinkStyle } from './link'

const baseStyle = {
  borderWidth: '0px',
  cursor: 'pointer',
  height: heights[0],
  textDecoration: 'none'
}

export const primaryButtonStyle = {
  ...baseStyle,
  ':disabled': {
    backgroundColor: colors.grey
  },
  ':hover': {
    backgroundColor: colors.primaryBlue
  },
  fontFamily: fonts.sans,
  fontSize: fontSizes.large,
  color: colors.white,
  textAlign: 'center' as 'center',
  lineHeight: lineHeights[4],
  fontWeight: fontWeights.light,
  backgroundColor: colors.buttonBlue,
  alignSelf: 'stretch',
  borderRadius: '3px',
  label: 'Button-primary',
}

export const linkButtonStyle = {
  ...baseStyle,
  ...baseLinkStyle,
  backgroundColor: 'transparent',
  textDecoration: 'underline'
}

export const buttons: ButtonStyles = {
  primary: primaryButtonStyle,
  link: linkButtonStyle
}
