import css from '@emotion/css'
import emotionReset from 'emotion-reset'
import stickyFooter from './stickyFooter'
import languageSelect from './languageSelect'
import privacyPolicy from './privacyPolicy'
import form from './form'

export const globalStyles = css`
${emotionReset}
${stickyFooter}
${languageSelect}
${privacyPolicy}
${form}
`
