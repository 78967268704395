import {AppTheme} from "../types";

import { theme as defaultTheme } from './default'
import { theme as portalTheme } from './portal'

export type ThemeId = 'default' | 'portal'

export const getThemeById = (themeId: ThemeId): AppTheme => {
  if (themeId === 'portal') {
    return portalTheme
  }

  return defaultTheme
}

export const getThemeIdForService = (service: string): ThemeId => {
  switch(service) {
    case 'health_tracker':
      return 'portal'
    default:
      return 'default'
  }
}