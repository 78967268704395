import { ClientInterface, DataLayer } from '../types/analytics'

// Google Analytics globals defined in ../public/index.html
declare const GA_MEASUREMENT_ID: string
declare const dataLayer: DataLayer

class Client implements ClientInterface {
  public readonly id: string

  public readonly dataLayer: DataLayer

  // This replaces the recommended gtag implementation, and expects that
  // the gtag library (https://www.googletagmanager.com/gtag/js) has been loaded
  // @see https://developers.google.com/analytics/devguides/collection/gtagjs/
  public constructor() {
    this.id = GA_MEASUREMENT_ID
    this.dataLayer = dataLayer
    this.pushToDataLayer('js', new Date())
    this.pushToDataLayer('config', this.id, { anonymize_ip: true })
  }

  // @see https://developers.google.com/analytics/devguides/collection/gtagjs/pages
  public pageview(location: string): void {
    this.pushToDataLayer('config', this.id, {
      page_location: location // eslint-disable-line @typescript-eslint/camelcase
    })
  }

  // @see https://developers.google.com/analytics/devguides/collection/gtagjs/events
  public event(event: string, params: object = {}): void {
    this.pushToDataLayer('event', event, params)
  }

  public error(event: string, params: object): void {
    this.pushToDataLayer('event', event, params)
  }

  // The recommended gtag implementation is `function gtag(){dataLayer.push(arguments);}`
  // Pushing an instance of Arguments causes the gtag library to load other libraries
  // as required (e.g. analytics.js when a pageview is recorded). Pushing anything
  // other than an instance of Arguments prevents any activity from actually being
  // recorded. Strange but true...
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private pushToDataLayer(..._args: unknown[]): void {
    // eslint-disable-next-line prefer-rest-params
    this.dataLayer.push(arguments)
  }
}

export const client: ClientInterface = new Client()
