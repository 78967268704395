import React from 'react'
import { useTranslation } from 'react-i18next'
import {withTheme} from "emotion-theming";
import { ReactComponent as AuthorizedIcon } from '../../svg/icon-authorized.svg'
import { Heading, Text } from '../../components/Library/Typography'
import { Flex } from '../../components/Library/Flex'
import { Footer } from '../../components/Footer'
import { LogoHeader } from '../../components/LogoHeader'
import {
  BorderBottom,
  InlineExternalLink,
  InlineInternalLink,
  PrimaryButton,
  LinkButton,
  Content,
} from '../../components/Library'
import {PageContent} from "../../components/PageContent";

interface Props {
  acceptTerms: () => void
  declineTerms: () => void
  clinicName: string | undefined
}

export const Layout: React.FC<Props> = withTheme(({
  acceptTerms,
  declineTerms,
  clinicName,
  theme
}) => {
  const { t } = useTranslation()
  return (
    <>
      <LogoHeader />

      <PageContent banner={theme.pageBannerStyle === 'curved' && {title: t('start.welcome')}}>

        <Content px={25} py={3} alignItems="center">
          <Flex flexDirection="column" alignItems="center" pb={0}>
            {theme.pageBannerStyle !== 'curved' && <AuthorizedIcon width="70" height="70" />}
            <Heading mb="0px">{t('acceptTerms.title')}</Heading>
          </Flex>
          <BorderBottom pb={3} mb={3}>
            <Text textStyle="normal" textAlign="center">
              {t('acceptTerms.reviewMessageTitle', { clinicName })}
            </Text>
          </BorderBottom>
          <Text textStyle="normal" pb={3}>
            {t('acceptTerms.chooseReceiveText')}{' '}
            <InlineExternalLink href="/help">nav.care/help</InlineExternalLink>.{' '}
            {t('acceptTerms.moreInformation')}{' '}
            <InlineInternalLink to="/privacy">
              {t('acceptTerms.privacyPolicy')}
            </InlineInternalLink>{' '}
            {t('acceptTerms.orSee')}{' '}
            <InlineInternalLink to="/help#faq">
              {t('acceptTerms.FAQ')}
            </InlineInternalLink>{' '}
            {t('acceptTerms.texingStop')}
          </Text>
          <Flex mb={4} flexDirection="column" alignItems="center">
            <Text textStyle="normal" pb={4}>
              {t('acceptTerms.acceptingNC')}{' '}
              <InlineInternalLink to="/terms">
                {t('acceptTerms.termsOfUse')}
              </InlineInternalLink>
            .
            </Text>
            <PrimaryButton data-testid="accept-terms" onClick={acceptTerms}>
              {t('acceptTerms.continue')}
            </PrimaryButton>
            <LinkButton onClick={declineTerms}>
              {t('acceptTerms.decline')}
            </LinkButton>
          </Flex>
        </Content>
      </PageContent>

      <Footer />
    </>
  )
})
