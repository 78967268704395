import css from '@emotion/css'

export default css`
  .privacy-policy {
      display:flex;
      flex-direction: column;
  }
  .privacy-policy .layout-content {
      overflow:hidden;
  }
`