import React from 'react'
import { Flex } from '../../components/Library/Flex'
import { Text } from '../../components/Library/Typography'

export interface ValidationErrorProps {
  validationError: string
}

export const ValidationError: React.SFC<ValidationErrorProps> = ({
  validationError
}) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    data-testid="verification-error"
  >
    <Text textStyle="error" mb={2} textAlign="left">
      {validationError}
    </Text>
  </Flex>
)
