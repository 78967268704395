import React, { ReactNode, ComponentProps } from 'react'
import { Link, LinkProps as InternalLinkProps } from 'react-router-dom'
import { UL, LI, ULProps, LIProps } from './Lists'
import { Button, ButtonProps } from './Button'
import { Input, LabelProps, InputProps } from './Input'
import { FlexProps, FlexContent, BorderFlex } from './Flex'
import { Text } from './Typography'
import { A, LinkProps as ExternalLinkProps } from './Anchor'
import {Fixed, FixedProps} from './Fixed'
import { BoxProps, Box } from './Box'
import { PProps } from './P'
import { Img, ImgProps } from './Img'

export const Image = <P extends ImgProps>(props: P) => <Img {...props} />

export const PrimaryButton = <P extends ButtonProps & ExternalLinkProps>(
  props: P
) => <Button variant="primary" {...props} />

export const PrimaryButtonLink = <P extends ButtonProps & InternalLinkProps>(
  props: P
) => <Button variant="primary" as={Link} {...props} />

export const LinkButton = <P extends ButtonProps & ExternalLinkProps>(
  props: P
) => <Button variant="link" {...props} />

export const LinkButtonLink = <P extends ButtonProps & InternalLinkProps>(
  props: P
) => <Button variant="link" as={Link} {...props} />

export const List = <P extends ULProps>(props: P) => (
  <UL ml={3} fontFamily="sans" fontSize="normal" lineHeight={1} {...props} />
)

export const ListItem = <P extends LIProps>(props: P) => (
  <LI mb={2} {...props} />
)

export const Label = <P extends PProps & LabelProps>(props: P) => (
  <Text textStyle="label" as="label" {...props} />
)

export type TextInputProps = InputProps & ComponentProps<'input'>

export const TextInput: React.FC<TextInputProps> = (props) => {
  return <Input variant="text" {...props} />
}

export const FormContent = <P extends FlexProps>(props: P) => (
  <Content as="form" alignItems="stretch" {...props} />
)

export const ExternalLink = <P extends ExternalLinkProps>(props: P) => (
  <A linkStyle="base" {...props} />
)

export const InlineExternalLink = <P extends ExternalLinkProps>(props: P) => (
  <A linkStyle="inline" {...props} />
)

export const InlineExternalSmallLink = <P extends ExternalLinkProps>(props: P) => (
  <A linkStyle="small" {...props} />
)

export const InternalLink = <P extends InternalLinkProps>(props: P) => (
  <A linkStyle="base" as={Link} {...props} />
)

export const InternalLinkHeader = <P extends InternalLinkProps>(props: P) => (
  <A linkStyle="header" textAlign="center" width="100%" as={Link} {...props} />
)

export const InlineInternalSmallLink = <P extends InternalLinkProps>(
  props: P
) => <A linkStyle="small" as={Link} {...props} />

export const InlineInternalLink = <P extends InternalLinkProps>(props: P) => (
  <A linkStyle="inline" as={Link} {...props} />
)

export const Header: React.FC<Omit<FixedProps, 'variant'> & { children?: ReactNode }> = props => (
  <Fixed as="header" variant="header" {...props} />
)

export const Content: React.FC<FlexProps> = props => (
  <FlexContent
    flex="1 0 auto"
    alignItems="flex-start"
    alignSelf="center"
    width="100%"
    flexDirection="column"
    maxWidth={{ medium: '640px', large: '640px' }}
    {...props}
  />
)


export const GroupingBox = <P extends BoxProps & { children: ReactNode }>(
  props: P
) => <Box pb={3} {...props} />

export const BorderBottom = <P extends FlexProps>(props: P) => (
  <BorderFlex
    m={'0px'}
    flexDirection="column"
    borderBottom="1px solid"
    borderColor="lightGrey"
    {...props}
  />
)
