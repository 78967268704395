import React from 'react'
import { Redirect } from 'react-router-dom'
import { LoadUrlResource } from '../dataProviders/LoadUrlResource'
import { ErrorMessage } from './ErrorMessage'

interface Props {
  urlToken: string
}

export const UrlLoader: React.SFC<Props> = ({ urlToken }) => {
  const params = new URLSearchParams(window.location.search)
  let language = params.get('language')
  if (!language || language.length === 0 || language === null) {
    language = 'EN'
  }

  return (
    <LoadUrlResource
      urlToken={urlToken}
      render={({ id, service, surveyId, checkIn }) => {
        switch (service) {
          case 'hci':
          case 'home_care_instructions':
            return <Redirect to={`/homecare-instructions/${id}?termsAccepted=true${language &&
              language.length > 0 &&
              `&language=${language.toLowerCase()}`}`} />
          case 'health_tracker': {
            // look up appropriate survey id from check-in data or url resource response, if none found use 'auto' as sentinel
            let surveyRoute = `/patient-monitoring/v2/survey/${checkIn?.enrollment?.surveyId || surveyId || 'auto'}`
            if (language) {
              surveyRoute = `${surveyRoute}?language=${language.toLowerCase()}`
            }
            // note: <Redirect> doesn't correctly handle routing according to the absolute path
            window.location.href = surveyRoute
            return undefined
          }
          case 'patient_education':
            return <Redirect to={`/patient-consent/${id}?surveyId=${surveyId}`} />
          default:
            return <ErrorMessage code={404} />
        }
      }}
    />
  )
}
