import React from 'react'
import { LoadPath, LoadProps } from './LoaderFactory'
import { ContentTicket } from '../types/models'

interface ResponseJSON {
  content_requests: ContentTicket[]
}
const CONTENT_REQUESTS_ENDPOINT = '/api/content_requests'

export const LoadAllContentTickets: React.SFC<LoadProps<ResponseJSON>> = ({
  render
}) => (
  <LoadPath<ResponseJSON> path={CONTENT_REQUESTS_ENDPOINT} render={render} />
)
