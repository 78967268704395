import { DateTime } from 'luxon'
import { SyntheticEvent, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Context as AnalyticsContext } from '../../analytics'
import { scrollToTop } from '../../browser/scrollToTop'
import { submitVerification } from './submitVerification'
import { isValid } from 'date-fns'

interface ErrorResponse {
  status: string
}

export interface BirthDate {
  month: string
  day: string
  year: string
}

export const useFormState = (urlToken: string) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [dateOfBirth, setBirthdate] = useState<BirthDate>({
    month: '',
    day: '',
    year: ''
  })
  const [zipcode, setZipcode] = useState('')
  // this field is essentially hardcoded. If, in the future, multiple forms of auth are required this value will have to be fetched based on the urlToken.
  const [verifyBirthdateOnly, setVerifyBO] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)
  const [tooManyLoginAttempts, setTooManyLoginAttempts] = useState(false)
  const [validationError, setValidationError] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)
  const analytics = useContext(AnalyticsContext)
  const [rememberMe, setRememberMe] = useState(true)
  const { t } = useTranslation()

  const submit = useCallback(
    () => async (e: SyntheticEvent) => {
      e.preventDefault()
      setSubmitting(true)

      const validateDob = (dateOfBirth:BirthDate): string => {    
        if (!dateOfBirth.month || !dateOfBirth.day || !dateOfBirth.year) {
          return t('verify.dobRequired') as string
        }
    
        if (dateOfBirth.year.length < 4) {
          return t('verify.yearDigits') as string
        }

        const isValidDateOfBirth = isValid(
          new Date(
            parseInt(dateOfBirth.year), 
            parseInt(dateOfBirth.month), 
            parseInt(dateOfBirth.day)
          )
        )
    
        if (!isValidDateOfBirth) {
          return t('verify.invalidDob')
        }
    
        return ''
      }

      let dobError = validateDob(dateOfBirth)
      setValidationError(dobError)
      if (dobError) {
        setSubmitting(false)
        return
      }

      const { month, day, year } = dateOfBirth

      if (month.length < 1 || year.length < 4 || day.length < 1) {
        return
      }

      if (
        !verifyBirthdateOnly &&
        !(firstName && lastName && zipcode.length === 5)
      ) {
        return
      }

      const parsedDate = DateTime.fromObject({
        day: parseInt(day, 10),
        month: parseInt(month, 10),
        year: parseInt(year, 10)
      })

      try {
        const response = await submitVerification(
          firstName,
          lastName,
          parsedDate.toFormat('yyyy-MM-dd'),
          zipcode,
          rememberMe,
          urlToken,
          verifyBirthdateOnly
        )

        scrollToTop()

        if (response.status === 200) {
          setIsLoggedIn(true)
          localStorage.setItem('authenticated', 'true')
          analytics.event('verification_succeeded')
          return
        }

        localStorage.removeItem('authenticated')
        analytics.event('verification_failed')

        const responseJson: ErrorResponse = await response.json()
        if (responseJson.status === 'too_many_failed_attempts') {
          setTooManyLoginAttempts(true)
          analytics.event('verification_attempts_exhausted')
          return
        }
      } finally {
        setSubmitting(false)
      }
      setHasErrors(true)
    },
    [
      firstName,
      lastName,
      dateOfBirth,
      zipcode,
      rememberMe,
      verifyBirthdateOnly,
      analytics,
      urlToken,
      t
    ]
  )

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    dateOfBirth,
    setBirthdate,
    zipcode,
    setZipcode,
    rememberMe,
    setRememberMe,
    verifyBirthdateOnly,
    setVerifyBO,
    isLoggedIn,
    hasErrors,
    submit,
    tooManyLoginAttempts,
    validationError,
    setValidationError,
    isSubmitting
  }
}
