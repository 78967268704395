import { parsePhoneNumberFromString } from 'libphonenumber-js'
import * as yup from 'yup'

const generalValidationMessage =
  'Please include a valid text message number or email address.'
const phoneNumberValidationMessage =
  'Please include a valid text message number.'
const emailValidationMessage = 'Please include a valid email address.'

export const validationSchema = yup.object().shape(
  {
    emailAddress: yup
      .string()
      .nullable()
      .when(['phoneNumber'], {
        is: (phoneNumber: string) => !phoneNumber,
        then: yup.string().required(generalValidationMessage)
      })
      .email(emailValidationMessage),
    phoneNumber: yup
      .string()
      .nullable()
      .when(['emailAddress'], {
        is: (emailAddress: string) => !emailAddress,
        then: yup.string().required(generalValidationMessage)
      })
      .test({
        message: phoneNumberValidationMessage,
        test(phoneNumber) {
          let isNumberValid = true

          if (phoneNumber) {
            const userPhoneNumber = parsePhoneNumberFromString(
              phoneNumber,
              'US'
            )
            isNumberValid = userPhoneNumber ? userPhoneNumber.isValid() : false
          }
          return isNumberValid
        }
      })
  },
  [['phoneNumber', 'emailAddress']]
)
