import React, { useContext, useEffect } from 'react'
import { Get, GetDataError } from 'restful-react'
import { Loading } from '../components/Loading'
import { Error } from '../components/Error'
import { ErrorAnalytics } from '../components/ErrorAnalytics'
import { Store } from '../state/Provider'

export interface LoadProps<M> {
  urlToken?: string
  render: (model: M) => React.ReactNode
}

export interface LoadPathProps<M> extends LoadProps<M> {
  path: string
}

interface RrpProps<M> {
  render: (model: M) => React.ReactNode
  response: M | null
  loading: boolean
  error: GetDataError<any> | null | undefined
  urlToken?: string
}

export const ResponseRenderProp: <M>(props: RrpProps<M>) => JSX.Element = ({
  render,
  response,
  loading,
  error,
  urlToken
}) => {
  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <>
          <ErrorAnalytics error={error} />
          <Error error={error} urlToken={urlToken} />
        </>
      ) : response && render ? (
        <>{render(response)}</>
      ) : (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <Error error={null as any} />
      )}
    </>
  )
}

export const LoadPath = <M,>({
  path,
  render,
  urlToken
}: LoadPathProps<M>): JSX.Element => {
  const { dispatch } = useContext(Store)

  useEffect(() => {
    if (urlToken) {
      dispatch({ type: 'SET_URL_TOKEN', payload: urlToken })
    }
  }, [dispatch, urlToken])

  return (
    <Get path={path} requestOptions={{ credentials: 'include' }}>
      {(response: M | null, { loading, error }) => {
        return (
          <ResponseRenderProp<M>
            {...{ render, response, loading, error, urlToken }}
          />
        )
      }}
    </Get>
  )
}
