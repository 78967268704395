import React from 'react'
import { MatchUrlTokenProps } from '../types/index'
import { UrlLoader } from './UrlLoader'

export const UrlRouteLoader: React.SFC<MatchUrlTokenProps> = ({
  match: {
    params: { urlToken }
  }
}) => {
  return <UrlLoader urlToken={urlToken} />
}
