import {fonts, fontSizes, lineHeights, colors, fontWeights} from './scalar'
import { TextStyles } from '../../types'

const baseTextStyle = {
  fontFamily: fonts.sans,
  fontSize: fontSizes.normal,
  lineHeight: lineHeights[1],
  color: colors.black,
  label: 'Text-base'
}

export const textStyles: TextStyles = {
  base: baseTextStyle,
  label: {
    ...baseTextStyle,
    fontSize: fontSizes.normal,
    lineHeight: lineHeights[3],
    label: 'Text-label'
  },
  error: {
    ...baseTextStyle,
    color: colors.red,
    label: 'Text-error'
  },
  small: {
    ...baseTextStyle,
    fontSize: fontSizes.small,
    label: 'Text-small'
  },
  normal: {
    ...baseTextStyle,
    fontSize: fontSizes.normal,
    label: 'Text-normal'
  },
  normalSpaced: {
    ...baseTextStyle,
    lineHeight: lineHeights[2],
    fontSize: fontSizes.normal,
    label: 'Text-normal'
  },
  tiny: {
    ...baseTextStyle,
    fontSize: fontSizes.tiny,
    label: 'Text-tiny'
  },
  tinyGrey: {
    ...baseTextStyle,
    fontSize: fontSizes.tiny,
    color: colors.grey,
    label: 'Text-tinyGrey'
  },
  notice: {
    ...baseTextStyle,
    fontSize: fontSizes.normal,
    background: colors.wintergreen,
    label: 'Text-notice'
  },
  boldSmall: {
    ...baseTextStyle,
    fontWeight: fontWeights.bold,
    fontSize: fontSizes.normal,
    label: 'Text-bold'
  },
  bold: {
    ...baseTextStyle,
    fontWeight: fontWeights.bold,
    label: 'Text-bold'
  },
  heading: {
    ...baseTextStyle,
    fontSize: fontSizes.large,
    lineHeight: lineHeights[2],
    label: 'Text-heading'
  },
  subheading: {
    ...baseTextStyle,
    fontSize: fontSizes.tiny,
    label: 'Text-subheading'
  },
  mediumHeading: {
    ...baseTextStyle,
    fontSize: fontSizes.normal,
    fontWeight: fontWeights.bold,
    label: 'Text-mediumHeading'
  },
  xlHeading: {
    ...baseTextStyle,
    fontSize: fontSizes.xlarge,
    fontWeight: fontWeights.light,
    lineHeight: lineHeights[2],
    textAlign: 'center',
    label: 'Text-xlHeading'
  },
  errorHeading: {
    ...baseTextStyle,
    color: colors.red,
    fontSize: fontSizes.xlarge,
    lineHeight: lineHeights[2],
    label: 'Text-errorHeading'
  },
  spacedHeading: {
    ...baseTextStyle,
    fontSize: fontSizes.aBitBigger,
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights[2],
    label: 'Text-spacedHeading'
  },
  faqHeading: {
    ...baseTextStyle,
    fontSize: fontSizes.somewhatBig,
    lineHeight: lineHeights[3],
    label: 'Text-faqHeading'
  },
  darkGrey: {
    ...baseTextStyle,
    fontSize: fontSizes.small,
    color: colors.darkGrey,
    lineHeight: lineHeights[3],
    label: 'Text-darkGrey'
  }
}
