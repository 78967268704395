import React from 'react'
import { RouterChildContext } from 'react-router-dom'
import { Layout } from './Layout'
import { LoadClinic } from '../../dataProviders/LoadClinic'

type Props = Pick<
  RouterChildContext<{ id: string }>['router']['route'],
  'match'
>

export const YourClinic: React.StatelessComponent<Props> = ({
  match: {
    params: { id }
  }
}) => <LoadClinic id={id} render={clinic => <Layout clinic={clinic[0]} />} />
