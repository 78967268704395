import { AppTheme } from '../../types'
import {
  colors,
  fonts,
  fontSizes,
  fontWeights,
  heights,
  lineHeights,
  space
} from './scalar'
import { globalStyles } from './globalStyles'
import { fixed } from './fixed'
import { textStyles } from './text'
import { buttons } from './button'
import { links } from './link'
import { breakpoints } from './breakpoints'
import { inputs } from './inputs'
import { icons } from './icons'

export const theme: AppTheme = {
  breakpoints,
  buttons,
  colors,
  fixed,
  fontSizes,
  fontWeights,
  fonts,
  heights,
  lineHeights,
  links,
  space,
  textStyles,
  globalStyles,
  inputs,
  icons,
  pageBannerStyle: 'curved'
}
