import React, { ComponentProps, Ref } from 'react'
import {
  alignItems,
  flexDirection,
  flexWrap,
  justifyContent,
  AlignItemsProps,
  FlexDirectionProps,
  FlexWrapProps,
  JustifyContentProps,
  borders,
  BorderProps,
  borderColor,
  BorderColorProps,
  position,
  bottom,
  PositionProps,
  BottomProps,
  OverflowProps,
  overflow,
  BoxShadowProps,
  boxShadow,
  fontFamily,
  FontFamilyProps,
  MinHeightProps,
  minHeight
} from 'styled-system'
import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { Box, BoxProps } from './Box'

export type FlexProps = BoxProps &
AlignItemsProps &
FlexDirectionProps &
FlexWrapProps &
FontFamilyProps &
PositionProps &
BottomProps &
MinHeightProps &
JustifyContentProps &
ComponentProps<'div'> &
BoxShadowProps &
OverflowProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: Ref<any>
}

export const Flex: React.FC<FlexProps> = styled(Box)(
  {
    display: 'flex',
    label: 'Flex'
  },
  alignItems,
  flexDirection,
  flexWrap,
  justifyContent,
  minHeight,
  position,
  bottom,
  overflow,
  boxShadow,
  fontFamily
)
Flex.displayName = 'Flex'

export const FlexContent: React.FC<FlexProps> = styled(Flex, {
  shouldForwardProp: isPropValid
})({
  WebkitOverflowScrolling: 'touch',
  label: 'Flex-content'
})

export const BorderFlex: React.FC<FlexProps &
BorderProps &
BorderColorProps> = styled(Flex)(
  {
    alignItems: 'center',
    label: 'Border-flex'
  },
  borders,
  borderColor
)

FlexContent.defaultProps = {
}
