import React, {
  KeyboardEvent,
  SetStateAction,
  Dispatch,
  ChangeEvent
} from 'react'
import { Omit } from 'lodash'
import { useTranslation } from 'react-i18next'
import { TextInputProps, TextInput, Label } from '../Library'
import { Flex } from '../Library/Flex'
import { BirthDate } from '../../pages/Verify/useFormState'
import { PProps } from '../Library/P'
import { LabelProps } from '../Library/Input'

interface Props extends Omit<TextInputProps, 'onChange'> {
  dateOfBirth: { month: string; day: string; year: string }
  setBirthdate: Dispatch<SetStateAction<BirthDate>>
  setValidationError: (n: string) => void
  hasError: boolean
}

function addLeadingZeroes(val: string, digits: number) {
  return val.padStart(digits,'0')
}

function allowNumbersOnly(e: KeyboardEvent<HTMLInputElement>) {
  const numRe = /\d/
  if (!numRe.test(e.key)) {
    e.preventDefault()
  }
}

const MAX_MONTH = 12
const MAX_DAY = 31

export const DateOfBirthInput: React.FC<Props> = ({
  dateOfBirth = {month:'', day: '', year: ''},
  setBirthdate,
  setValidationError,
  hasError,
  ...props
}) => {
  const { t } = useTranslation()

  const handleBlur = (
    input: string,
    validator?: (() => { isValid: boolean; msg: string }) | null,
    formatter?: ((input: string) => string) | null,
    setter?: ((input: string) => void) | null
  ): void => {
    if (formatter && setter) {
      const formattedInput = formatter(input)
      if (formattedInput !== input) {
        setter(formattedInput)
      }
    }

    if (validator) {
      const { msg } = validator()
      setValidationError(msg)
    }
  }

  function handleDateChange(
    key: 'month' | 'day' | 'year',
    e: ChangeEvent<HTMLInputElement>
  ) {
    e.persist()
    setBirthdate(prev => ({ ...prev, [key]: e.target.value }))
  }

  function validateNumInRange(value: number|string, min: number, max: number): boolean  {
    if (typeof(value) == 'string') {
      value = parseInt(value)
    }

    let isValid
    if (isNaN(value)) {
      isValid = false
    } else {
      isValid = value <= max && value >= min
    }

    return isValid
  }

  function validateMonth() {
    let isValid = dateOfBirth.day.length === 0 || validateNumInRange(dateOfBirth.month, 1, MAX_MONTH)
    let msg = isValid || dateOfBirth.month.length === 0 ? '' : t('verify.invalidDob') 
    return {isValid, msg}
  }

  function validateDay() {
    let isValid = dateOfBirth.day.length === 0 || validateNumInRange(dateOfBirth.day, 1, MAX_DAY)
    let msg = isValid || dateOfBirth.day.length === 0 ? '' : t('verify.invalidDob') 
    return {isValid, msg}
  }

  function validateYear() {
    let isValid = true
    let msg = ''
    if (dateOfBirth.year.length !== 0) {
      if (dateOfBirth.year.length < 4) {
        isValid = false
        msg = t('verify.yearDigits') 
      } else if (!validateNumInRange(dateOfBirth.year, 1900, new Date().getFullYear())) {
        isValid = false
        msg = t('verify.invalidDob')
      }
    }

    return { isValid, msg }
  }

  function validateDateOfBirth() {
    let validationResult = validateMonth()
    if (!validationResult.isValid) {
      return validationResult
    }

    validationResult = validateDay()
    if (!validationResult.isValid) {
      return validationResult
    }

    validationResult = validateYear()
    if (!validationResult.isValid) {
      return validationResult
    }

    return {isValid: true, msg: ''}
  }

  const DateLabel = <P extends PProps & LabelProps>(props: P) => (
    <Label textStyle="label" as="label" style={{fontSize:'14px', color: '#5E5E5E', fontWeight:600 }} {...props} />
  )

  const monthId = `${props.id}_month`
  const dayId = `${props.id}_day`
  const yearId = `${props.id}_year`
  return (
    <Flex id={props.id} flexDirection="row" justifyContent='space-between'>
      <Flex flexDirection="column">
        <DateLabel htmlFor={monthId}>{t('verify.month')}</DateLabel>
        <TextInput
          {...props}
          autoComplete="off"
          id={monthId}
          aria-label={monthId}
          data-testid={monthId}
          type="text"
          placeholder="MM"
          pattern="[0-9]*"
          inputMode="numeric"
          maxLength={2}
          onKeyPress={allowNumbersOnly}
          className={dateOfBirth && dateOfBirth.month ? 'notempty' : ''}
          formNoValidate
          value={dateOfBirth.month}
          onChange={handleDateChange.bind(undefined, 'month')}
          onBlur={e => {
            handleBlur(
              e.target.value,
              validateDateOfBirth,
              input => {
                return input.length > 0 ?
                  addLeadingZeroes(input, 2) :
                  ''
              },
              input => setBirthdate(prev => ({ ...prev, month: input }))
            )
          }}
          borderColor={hasError ? 'red' : 'lightGrey'}
          size={5}
        />
      </Flex>

      <Flex flexDirection="column" >
        <DateLabel htmlFor={dayId}>{t('verify.day')}</DateLabel>
        <TextInput
          {...props}
          autoComplete="off"
          id={dayId}
          aria-label={dayId}
          data-testid={dayId}
          type="text"
          placeholder="DD"
          pattern="[0-9]*"
          inputMode="numeric"
          maxLength={2}
          onKeyPress={allowNumbersOnly}
          className={dateOfBirth.day ? 'notempty' : ''}
          formNoValidate
          value={dateOfBirth.day}
          onChange={handleDateChange.bind(undefined, 'day')}
          onBlur={e => {
            handleBlur(
              e.target.value,
              validateDateOfBirth,
              input => {
                return input.length > 0 ?
                  addLeadingZeroes(input, 2) :
                  ''
              },
              input => setBirthdate(prev => ({ ...prev, day: input }))
            )
          }}
          borderColor={hasError ? 'red' : 'lightGrey'}
          size={5}
        />
      </Flex>

      <Flex flexDirection="column">
        <DateLabel htmlFor={yearId}>{t('verify.year')}</DateLabel>
        <TextInput
          {...props}
          autoComplete="off"
          id={yearId}
          aria-label={yearId}
          data-testid={yearId}
          type="text"
          placeholder={t('verify.yearPlaceholder')}
          pattern="[0-9]*"
          inputMode="numeric"
          maxLength={4}
          onKeyPress={allowNumbersOnly}
          className={dateOfBirth.year ? 'notempty' : ''}
          formNoValidate
          value={dateOfBirth.year}
          onChange={handleDateChange.bind(undefined, 'year')}
          onBlur={e => {
            handleBlur(
              e.target.value, 
              validateDateOfBirth
            )
          }}
          borderColor={hasError ? 'red' : 'lightGrey'}
          size={9}
        />
      </Flex>
    </Flex>
  )
}
