import styled from '@emotion/styled'
import { buttonStyle, space, ButtonStyleProps, SpaceProps, WidthProps } from 'styled-system'
import isPropValid from '@emotion/is-prop-valid'
import React from 'react'

export type ButtonProps = ButtonStyleProps & SpaceProps & WidthProps

export const Button: React.FC<ButtonProps> = styled('button', {
  shouldForwardProp: isPropValid
})(space, buttonStyle) as React.FC<ButtonProps>
Button.displayName = 'Button'
