import React from 'react'
import { Redirect } from 'react-router-dom'
import { Layout } from './Layout'
import { useFormState } from './useFormState'
import { MatchUrlTokenProps } from '../../types'
import { UrlLoader } from '../../components/UrlLoader'
import { LoadPreview } from '../../dataProviders/LoadPreview'

export const Verify: React.SFC<MatchUrlTokenProps> = ({
  match: {
    params: { urlToken }
  }
}) => {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    dateOfBirth,
    setBirthdate,
    zipcode,
    setZipcode,
    rememberMe,
    setRememberMe,
    verifyBirthdateOnly,
    isLoggedIn,
    hasErrors,
    tooManyLoginAttempts,
    submit,
    validationError,
    setValidationError,
    isSubmitting
  } = useFormState(urlToken)

  if (isLoggedIn) {
    return <UrlLoader urlToken={urlToken} />
  }

  if (tooManyLoginAttempts) {
    return (
      <Redirect
        to={{ pathname: '/verification-error', search: window.location.search }}
      />
    )
  }

  return (
    <LoadPreview
      urlToken={urlToken}
      render={() => {
        return (
          <Layout
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            dateOfBirth={dateOfBirth}
            setBirthdate={setBirthdate}
            zipcode={zipcode}
            setZipcode={setZipcode}
            rememberMe={rememberMe}
            setRememberMe={setRememberMe}
            verifyBirthdateOnly={verifyBirthdateOnly}
            submit={submit()}
            hasErrors={hasErrors}
            validationError={validationError}
            setValidationError={setValidationError}
            isSubmitting={isSubmitting}
          />
        )
      }}
    />
  )
}
