import React from 'react'
import { width } from 'styled-system'
import styled from '@emotion/styled'

export const Img: React.FC = styled('img')(
  {
    label: 'Img'
  },
  width
)
Img.displayName = 'Img'

export type ImgProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>
