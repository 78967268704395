import React from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Flex } from './Library/Flex'
import { Icon } from "./Library/Icon";

export const CloseIcon: React.FC = () => {
  return (
    <Flex flexDirection="column" alignItems="center">
      <Icon variant="secondary" icon={faTimes} size="2x" />
    </Flex>
  )
}
