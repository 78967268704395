// eslint-disable-next-line import/named
import { LinkStyles } from '../../types'
import { fonts, fontSizes, lineHeights, colors } from './scalar'

export const baseLinkStyle = {
  fontFamily: fonts.sans,
  fontSize: fontSizes.normal,
  lineHeight: lineHeights[3],
  color: colors.primaryBlue,
  label: 'Link-base',
  textDecoration: 'none',

  ':hover': {
    textDecoration: 'underline',
  },
}

const small = {
  ...baseLinkStyle,
  fontSize: fontSizes.small,
  lineHeight: lineHeights[1],

  label: 'Link-small'
}

export const links: LinkStyles = {
  base: baseLinkStyle,
  small,
  inline: {
    ...baseLinkStyle,
    lineHeight: lineHeights[1],
    label: 'Link-inline'
  },
  header: {
    ...baseLinkStyle,
    fontSize: fontSizes.large,
    label: 'Link-header'
  }
}
