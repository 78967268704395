import styled from '@emotion/styled'
import {
  ColorProps,
  FontFamilyProps,
  FontWeightProps,
  LetterSpacingProps,
  LineHeightProps,
  TextStyleProps,
  SpaceProps,
  fontFamily,
  fontWeight,
  letterSpacing,
  lineHeight,
  textStyle,
  color,
  style
} from 'styled-system'

import {TextVariant} from '../../styles/types'

const whiteSpace = style({
  prop: 'whiteSpace',
  cssProperty: 'whiteSpace',
  key: 'whiteSpace'
})

// Used dangerouslySetInnerHTML to set HCI message as innerHTML for div
// No need to worry about sanitizing, as it is sanitized on server side
export interface BaseProps {
  whiteSpace?: string,
  dangerouslySetInnerHTML?: { __html: string }
}

export type MProps = BaseProps &
ColorProps &
FontFamilyProps &
FontWeightProps &
LetterSpacingProps &
LineHeightProps &
SpaceProps &
TextStyleProps & {
  textStyle?: TextVariant
}

export const Message: React.FC<MProps> = styled('div')(
  {
    boxSizing: 'border-box',
    fontFamily: 'sans',
    fontSize: 'normal',
    color: 'black',
    lineHeight: '1',
    h4: {
      fontWeight: 'bold',
      color: 'darkGrey'
    },
    ul: {
      display: 'inline'
    },
    li: {
      marginBottom: '10px'
    },
    a: {
      color: 'darkGreen',
      textDecoration: 'none'
    },
    hr: {
      color: 'darkGrey'
    }
  },
  color,
  fontFamily,
  fontWeight,
  letterSpacing,
  lineHeight,
  textStyle,
  whiteSpace
)
Message.displayName = 'Message'
