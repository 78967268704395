import React from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Flex } from './Library/Flex'
import { BorderBottom, InlineExternalSmallLink } from './Library'
import { LanguageSelect } from './LanguageSelect'
import { Text } from './Library/Typography'
import {Fixed} from "./Library/Fixed";


export const Footer: React.ComponentClass = withRouter(() => {
  const { t } = useTranslation()
  return (
    <Fixed variant="footer">
      <BorderBottom pb={3} flexDirection="row">
        <Text textStyle="small">{t('common.nc')}</Text>
        <InlineExternalSmallLink
          pl={2}
          href="https://www.navigatingcare.com/patient/contact-us.html"
          target="_blank"
        >
          {t('footer.help')}
        </InlineExternalSmallLink>
        <LanguageSelect />
      </BorderBottom>
      <Text pt={3} pb={1} textStyle="small">
        {t('common.copy')}
      </Text>
      <Flex>
        <InlineExternalSmallLink
          href="https://www.navigatingcancer.com/terms-of-use/"
          target="_blank"
        >
          {t('footer.termsOfUse')}
        </InlineExternalSmallLink>{' '}
        <InlineExternalSmallLink
          pl={2}
          href="https://www.navigatingcancer.com/privacy-policy/"
          target="_blank"
        >
          {t('footer.privacyPolicy')}
        </InlineExternalSmallLink>
      </Flex>
    </Fixed>
  )
}
)
