import React, { useContext, SyntheticEvent } from 'react'
import { Layout } from './Layout'
import { jsonRequest } from '../../api/jsonRequest'
import { Context as AnalyticsContext } from '../../analytics'

const signOut = async () => {
  await jsonRequest(`/api/sessions/1`, { method: 'DELETE' })

  localStorage.removeItem('authenticated')

  window.history.replaceState({}, 'Welcome', '/')
  window.location.reload()
}

const goBack = (e: SyntheticEvent) => {
  e.preventDefault()
  window.history.back()
}

export const ConfirmSignOut = () => {
  const analytics = useContext(AnalyticsContext)
  const onSignOut = () => {
    analytics.event('signed_out')
    signOut()
  }

  return <Layout onConfirm={onSignOut} onGoBack={goBack} />
}
