import { split, trim, map, fromPairs } from 'lodash'

export function getCookieValue(name: string, cookie = document.cookie) {
  const pairs = map(split(cookie, ';'), trim)
  const splitPairs = map(pairs, p => split(p, '='))
  const cookieObject = fromPairs(splitPairs)
  const value = cookieObject[name]

  if (value) {
    return decodeURIComponent(value)
  }

  return null
}
