import React from 'react'
import { LoadPath, LoadProps } from './LoaderFactory'
import { Clinic } from '../types/models'

interface Props<M> extends LoadProps<M> {
  id: string
}

const getPath = (id: string) => `/api/clinics/${id}`

export const LoadClinic: React.SFC<Props<Clinic[]>> = ({ id, render }) => (
  <LoadPath<Clinic[]> path={getPath(id)} render={render} />
)
