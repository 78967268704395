import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { YourClinic } from './pages/YourClinic'
import { Start } from './pages/Start'
import { Verify } from './pages/Verify'
import { AcceptTerms } from './pages/AcceptTerms'
import { ViewMessage } from './pages/ViewMessage/index'
import { ListMessages } from './pages/ListMessages'
import { TooManyLoginAttempts } from './pages/TooManyLoginAttempts'
import { ConfirmSignOut } from './pages/ConfirmSignOut'
import { Welcome } from './pages/Welcome'
import { ViewPreferences } from './pages/ViewPreferences'
import { EditPreferences } from './pages/EditPreferences'
import { TermsDeclined } from './pages/TermsDeclined'
import { UrlRouteLoader } from './components/UrlRouteLoader'
import { PatientConsent } from './pages/PatientConsent'
import { PrintUnsignedConsent } from './pages/PrintUnsignedConsent'

export const Routes = () => {
  return (
    <Switch>
      <Route path="/homecare-instructions/:id" component={ViewMessage} />
      <Route path="/patient-education/:id" component={ViewMessage} />
      <Route path="/homecare-instructions" component={ListMessages} />
      <Route path="/preferences/edit" component={EditPreferences} />
      <Route path="/preferences" component={ViewPreferences} />
      {/* /clinic/ is already taken by health_tracker_client; hence /your-clinic/ */}
      <Route path="/your-clinic/:id" component={YourClinic} />
      <Route path="/confirm-sign-out" component={ConfirmSignOut} />

      <Route path="/verify/:urlToken" component={Verify} />
      <Redirect path="/verify" to={{ pathname: '/' }} />
      <Route path="/patient-consent/:id" component={PatientConsent} />
      <Route path="/accept-terms" component={AcceptTerms} />
      <Route path="/start/:urlToken" component={Start} />
      <Route
        path="/help"
        component={() => {
            window.location.href =
              'https://www.navigatingcare.com/patient/contact-us.html'
            return null
        }}
      />
      <Route
        path="/terms"
        component={() => {
          window.location.href =
            'https://www.navigatingcancer.com/terms-of-use/'
          return null
        }}
      />
      <Route
        path="/privacy"
        component={() => {
          window.location.href =
            'https://www.navigatingcancer.com/privacy-policy/'
          return null
        }}
      />
      <Route path="/terms-declined/:urlToken" component={TermsDeclined} />
      <Route path="/url-loader/:urlToken" component={UrlRouteLoader} />
      <Route path="/verification-error" component={TooManyLoginAttempts} />
      <Route
        path="/print-unsigned-consent/:locale"
        component={PrintUnsignedConsent}
      />
      <Redirect
        path="/start"
        to={{ pathname: '/', search: window.location.search }}
      />
      <Redirect
        path="/support"
        to={{ pathname: '/help', search: window.location.search }}
      />
      <Redirect
        path="/:urlToken"
        to={{
          pathname: '/url-loader/:urlToken',
          search: window.location.search
        }}
      />
      <Route exact path="/" component={Welcome} />
      <Redirect to={{ pathname: '/', search: window.location.search }} />
    </Switch>
  )
}
