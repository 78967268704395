import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { en } from './translate/en'
import { es } from './translate/es'
// the translations
// (tip move them in a JSON file and import them)
const resources: i18n.Resource = {
  en: {
    translation: en
  },
  es: {
    translation: es
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
