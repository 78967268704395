import React, { useContext, useLayoutEffect } from 'react'
import { LoadPath, LoadProps } from './LoaderFactory'
import { UrlResource } from '../types/models'
import { Store } from '../state/Provider'
import { setService } from '../state/setService'

interface Props<M> extends LoadProps<M> {
  urlToken: string
}

const getPath = (urlToken: string) => `/api/url_resources/${urlToken}`

const UrlResourceWrapper: React.FC<{
  urlResource: UrlResource
  render: (u: UrlResource) => React.ReactNode
}> = ({ urlResource, render }) => {
  const { dispatch } = useContext(Store)

  // firing a layout effect here because setting the service can trigger layout changes
  useLayoutEffect(() => {
    setService(dispatch, urlResource.service)
  })

  return <>{render(urlResource)}</>
}

export const LoadUrlResource: React.SFC<Props<UrlResource>> = ({
  urlToken,
  render
}) => {
  const wrappedRender = (urlResource: UrlResource) => (
    <UrlResourceWrapper urlResource={urlResource} render={render} />
  )
  return (
    <LoadPath<UrlResource>
      path={getPath(urlToken)}
      render={wrappedRender}
      urlToken={urlToken}
    />
  )
}
