import React from 'react'
import { InternalLink } from '../../components/Library'

interface Props {
  title: string
  to: string
  onClick: () => void
}

export const MenuListItem: React.SFC<Props> = ({ title, to, onClick }) => (
  <InternalLink mb={1} to={to} onClick={onClick} onKeyPress={onClick}>
    {title}
  </InternalLink>
)
