import React from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutWrapper } from '../../layouts/LayoutWrapper'
import { Text } from '../../components/Library/Typography'
import { GroupingBox, InternalLink } from '../../components/Library'

interface Props {
  sms: string
  email: string
}

export const Layout: React.FC<Props> = ({ sms, email }) => {
  const { t } = useTranslation()
  return (
    <LayoutWrapper title={t('viewPreferences.title')}>
      <GroupingBox>
        <Text pb={1} textStyle="boldSmall">
          {t('viewPreferences.mobileNo')}
        </Text>
        <Text textStyle="normal" data-testid="preferences-sms">
          {sms}
        </Text>
      </GroupingBox>
      <GroupingBox>
        <Text pb={1} textStyle="boldSmall">
          {t('viewPreferences.emailAddress')}
        </Text>
        <Text textStyle="normal" data-testid="preferences-email">
          {email}
        </Text>
      </GroupingBox>
      <InternalLink to="preferences/edit">
        {t('viewPreferences.edit')}
      </InternalLink>
    </LayoutWrapper>
  )
}
