import React, { useContext } from 'react'
import { Layout } from './Layout'
import { Context as AnalyticsContext } from '../../analytics'
import { LoadPreview } from '../../dataProviders/LoadPreview'
import { MatchUrlTokenProps } from '../../types'

export const Start: React.FunctionComponent<MatchUrlTokenProps> = ({
  match: {
    params: { urlToken }
  }
}) => {
  const analytics = useContext(AnalyticsContext)
  return (
    <LoadPreview
      urlToken={urlToken}
      render={() => {
        return (
          <Layout
            urlToken={urlToken}
            onStartButtonClick={() => analytics.event('started_verification')}
          />
        )
      }}
    />
  )
}
