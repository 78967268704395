import React from 'react'
import { LoadPath, LoadProps } from './LoaderFactory'
import { ContentTicket } from '../types/models'

interface Props<M> extends LoadProps<M> {
  contentTicketId: string
}

const getPath = (id: string) => `/api/content_requests/${id}`

export const LoadContentTicket: React.FC<Props<ContentTicket>> = ({
  contentTicketId,
  render
}) => {
  return (
    <LoadPath<ContentTicket> path={getPath(contentTicketId)} render={render} />
  )
}
