import { useState, useCallback, useEffect } from 'react'
import { jsonRequest } from '../../api/jsonRequest'
import { Preferences } from '../../types/models'
import { validationSchema } from './validationSchema'
import { ValidationError } from 'yup'

export type ErrorMap = {
  emailAddress: string
  phoneNumber: string
  general: string
}

function updatePreferences({ email, sms }: Preferences) {
  return jsonRequest('/api/preferences', {
    method: 'PUT',
    body: JSON.stringify({
      preferences: {
        email,
        sms
      }
    })
  })
}

const initialErrorState: ErrorMap = {
  emailAddress: '',
  phoneNumber: '',
  general: ''
}

export const useFormState = () => {
  const [email, setEmail] = useState('')
  const [sms, setSms] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [errorMessageMap, setErrorMessageMap] = useState(initialErrorState)

  const validateAndSubmitForm = (phoneNumber: string, email: string) => {
    const formObj = {
      emailAddress: email,
      phoneNumber: phoneNumber
    }
    const validationOptions = {
      abortEarly: false
    }

    validationSchema
      .validate(formObj, validationOptions)
      .then(() => {
        setErrorMessageMap(initialErrorState)
        submit()
      })
      .catch((errorObject: ValidationError) => {
        setValidationMessages(errorObject)
      })
  }

  const setValidationMessages = (errorObject: ValidationError) => {
    if (errorObject.inner) {
      let errorMap: any = Object.assign({}, initialErrorState)
      errorObject.inner.forEach((element: any) => {
        if (element.type === 'required') {
          // With current validation rules, it only occurs if both fields are blank
          errorMap['general'] = element.message
        } else {
          errorMap[element.path] = element.message
        }
      })
      setErrorMessageMap(errorMap)
    }
  }

  const submit = useCallback(async () => {
    setSubmitting(true)
    const response = await updatePreferences({ email, sms })

    if (response.status === 200) {
      window.location.assign('/preferences')
      setSubmitting(false)
      return
    }

    setSubmitting(false)
  }, [email, sms])

  useEffect(() => {
    jsonRequest('/api/preferences', {
      method: 'GET'
    })
      .then(res => res.json())
      .then(({ email, sms }: Preferences) => {
        setEmail(email!)
        setSms(sms!)
      })
  }, [])

  return {
    sms,
    email,
    setSms,
    setEmail,
    submitting,
    errorMessageMap,
    validateAndSubmitForm,
    setErrorMessageMap
  }
}
