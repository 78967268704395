import React, { useState } from 'react'
import { useEffect } from 'react'
import { ReactComponent as Logo } from '../../svg/nc-logo.svg'
// eslint-disable-next-line import/named
import { MatchProps } from '../../types'
import { getPatientConsentContents } from '../PatientConsent/patientConsent'
import './style.css'
import { Loading } from '../../components/Loading'

type LocaleProps = MatchProps<{ locale: string }>
const temp: any = {}
export const PrintUnsignedConsent: React.FC<LocaleProps> = ({
  match: {
    params: { locale }
  }
}) => {
  
  const [consentContent, setConsentContent] = useState(temp)

  useEffect(()=>{
    getPatientConsentContents(locale).then((response)=> {
      return response.json()
    }).then((response1) => {
        setConsentContent(response1)
      })
  }, [locale])

  useEffect(()=>{
    if(consentContent.consent_content !== undefined){
      window.print()
    }
  }, [consentContent])

  if(consentContent.consent_content === undefined){
    return <Loading />
  }

  const { consent_content } = consentContent

  const sanitizeHTML = (message: string, message1: string ) => {
    const aTag = `<a href="mailto:support@navigatingcare.com">support@navigatingcare.com</a>`
    const htmlMessage = `<div><p>${message} ${aTag} ${message1} </p></div>` 
    return {
      __html: htmlMessage
    }
  }
  
  return (
    <div>
      <Logo className='logo'/>
      <hr/>
      <div className="consent-titles">
        <h1 className='consent-title'>{consent_content.title}</h1>
      </div>
      <div className="consent-texts">
        <p className="consent-text">
          {consent_content.pt_ed_subtext1}{' '}
          <b>{consent_content.pt_ed_subtext2}</b>{' '}
          {consent_content.pt_ed_subtext3}
        </p>
        <p className="consent-info">
          {consent_content.clinic_can_provide_education}
        </p>
        <p className="consent-info">
          {consent_content.these_materials_are_provided}
        </p>
        <p className="consent-info privacy">
          {consent_content.your_personal_information_will_not_be_shared1}{' '}
          <a href="https://www.navigatingcare.com/privacy-policy" target='_blank' rel="noopener noreferrer">{consent_content.privacy_policy}</a>{' '}
          {consent_content.your_personal_information_will_not_be_shared2}
        </p>
        {consent_content.more_information_about && <p className="consent-info more">
          {consent_content.more_information_about}
        </p>}
        <ul className="legal-text">
          <li>{consent_content.by_participating_you_authorize}</li>
          <li>{consent_content.authorization_expire}</li>
          <li>{consent_content.in_order_to_personalize}</li>
          <li>{consent_content.clinic_will_not_condition_treatment}</li>
          <li>{consent_content.may_receive_compensation}</li>
          <li>
            {consent_content.you_may_opt_out1}
            {' '}<a href="mailto:support@navigatingcare.com">support@navigatingcare.com</a>{' '}
            {consent_content.you_may_opt_out2}
          </li>
          <li>
            <div dangerouslySetInnerHTML={sanitizeHTML(consent_content.you_may_request_a_copy, consent_content.you_may_immediately_access)}></div>
          </li>
        </ul>
        {consent_content.i_agree_to_use && <p className="consent-info">
          {consent_content.i_agree_to_use}
        </p>}
      </div>
    </div>
  )
}
