import React from 'react'
import { Action } from './reducers'
import { Clinic } from '../types/models'

export function setClinics(
  dispatch: React.Dispatch<Action>,
  clinics: Clinic[]
) {
  if (clinics !== undefined && clinics !== null && clinics.length > 0) {
    const clinic= clinics[0]
    dispatch({ type: 'SET_CLINIC_DETAILS', payload: {
      clinicId: clinic.clinicId,
      clinicName: clinic.name,
      clinicPhoneNumber: clinic.phoneNumber
    } })

    localStorage.setItem('clinicId', clinics[0].clinicId.toString())
    localStorage.setItem('clinicName', clinics[0].name)
    localStorage.setItem('clinicPhoneNumber', clinics[0].phoneNumber)
  }
}
