import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutWrapper } from '../../layouts/LayoutWrapper'
import { Heading, Text } from '../../components/Library/Typography'
import { PrimaryButton, InlineInternalLink } from '../../components/Library'

interface Props {
  onConfirm: () => void
  onGoBack: (e: SyntheticEvent) => void
}

export const Layout: React.FC<Props> = ({ onConfirm, onGoBack }) => {
  const { t } = useTranslation()
  return (
    <LayoutWrapper title={t('confirmSignOut.signOut')}>
      <Heading alignSelf="center" mb={3}>
        {t('confirmSignOut.title')}
      </Heading>
      <Text textStyle="normal" mx={3} mb={5} textAlign="center">
        {t('confirmSignOut.subTitle')}{' '}
        <InlineInternalLink to="" onClick={onGoBack}>
          {t('confirmSignOut.staySignIn')}
        </InlineInternalLink>
        .
      </Text>
      <PrimaryButton data-testid="signout" mb={3} onClick={onConfirm}>
        {t('confirmSignOut.signOut')}
      </PrimaryButton>
    </LayoutWrapper>
  )
}
