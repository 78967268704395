import React from 'react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { Layout } from './Layout'
import { LoadPreferences } from '../../dataProviders/LoadPreferences'

export const ViewPreferences = () => (
  <LoadPreferences
    render={({ sms, email }) => {
      const parsedPhoneNumber = sms && parsePhoneNumberFromString(sms, 'US')
      const formattedNumber =
        parsedPhoneNumber && parsedPhoneNumber.isValid()
          ? parsedPhoneNumber.formatNational()
          : 'None'
      const emailstr = email || 'None'

      return <Layout sms={formattedNumber} email={emailstr} /> //
    }}
  />
)
