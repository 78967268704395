import React from "react";
import {withTheme} from "emotion-theming";
import {FlexProps} from "./Library/Flex";
import {Fixed} from "./Library/Fixed";
import {PageBanner, PageBannerProps} from "./PageBanner";
import {PageBannerVariant, PageContentVariant} from "../styles/types";

// TODO: simplify this in ts 4+ using template literal types
export type PageVariant = "form" | "info" | "error"

export type PageContentProps = FlexProps & { pageVariant?: PageVariant, banner: false | PageBannerProps}
export const PageContent: React.FC<PageContentProps> = withTheme((props) => {
  const variant = `${props.pageVariant || 'info'}PageContent` as PageContentVariant
  const bannerVariant = `${props.pageVariant || 'info'}PageBanner` as PageBannerVariant
  return (
    <Fixed variant={variant} {...props} >
      {props.banner && <PageBanner variant={bannerVariant}
        coverColor={props.theme.fixed[variant].backgroundColor}
        {...props.banner} />
      }

      {props.children}
    </Fixed>
  )
})