import { Reducer } from 'react'
import { isEqual } from 'lodash'
import { ThemeId } from '../styles/themes'

export interface State {
  clinicId: string | undefined
  clinicName: string | undefined
  clinicPhoneNumber: string | undefined
  service: string | undefined
  urlToken: string | undefined
}

type Actions =
  | 'SET_CLINIC_ID'
  | 'SET_CLINIC_NAME'
  | 'SET_CLINIC_DETAILS'
  | 'SET_SERVICE'
  | 'SET_URL_TOKEN'

export interface Action {
  type: React.ReducerAction<Reducer<State, Actions>>
  payload: unknown
}

const nextState: Reducer<State, Action> = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_CLINIC_ID':
      return { ...state, clinicId: action.payload as string }
    case 'SET_CLINIC_NAME':
      return { ...state, clinicName: action.payload as string }
    case 'SET_CLINIC_DETAILS':
      return { ...state, ...(action.payload as Record<string, unknown>) }
    case 'SET_SERVICE':
      return { ...state, service: action.payload as ThemeId }
    case 'SET_URL_TOKEN':
      sessionStorage.setItem('urlToken', action.payload as string)
      return { ...state, urlToken: action.payload as string }
    default:
      return state
  }
}

export const reducer: Reducer<State, Action> = (
  state: State,
  action: Action
) => {
  const next = nextState(state, action)
  if (isEqual(state, next)) {
    return state
  }

  return next
}
