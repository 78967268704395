import React from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutWrapper } from '../../layouts/LayoutWrapper'
import {
  TextInput,
  PrimaryButton,
  Label,
  FormContent,
  InternalLink
} from '../../components/Library'
import { PhoneInput } from '../../components/PhoneInput'
import { ValidationError } from '../Verify/ValidationError'
import { ErrorMap } from './useFormState'

interface Props {
  sms: string
  email: string
  setSms: (sms: string) => void
  setEmail: (email: string) => void
  submitting: boolean
  errorMessageMap: ErrorMap
  validateAndSubmitForm: (sms: string, email: string) => void
  setErrorMessageMap: (errorObject: ErrorMap) => void
}

export const Layout: React.FC<Props> = ({
  sms,
  email,
  setSms,
  setEmail,
  submitting,
  errorMessageMap,
  validateAndSubmitForm,
  setErrorMessageMap
}) => {
  const { t } = useTranslation()
  return (
    <LayoutWrapper title={t('editPreferences.title')}>
      <FormContent mb={3} width="100%">
        <Label textStyle="darkGrey" fontWeight="bold" htmlFor="preferences-sms">
          {t('editPreferences.mobileNo')}
        </Label>
        <PhoneInput
          id="preferences-sms"
          data-testid="preferences-sms"
          aria-label="preferences-sms"
          pattern="[0-9]*"
          phone={sms}
          type="tel"
          onChange={() => {
            if (errorMessageMap.phoneNumber) {
              setErrorMessageMap({
                ...errorMessageMap,
                phoneNumber: ''
              })
            }
          }}
          onBlur={event => {
            setSms(event.target.value)
          }}
        />
        {errorMessageMap && errorMessageMap.phoneNumber && (
          <ValidationError validationError={errorMessageMap.phoneNumber} />
        )}
        <Label
          textStyle="darkGrey"
          fontWeight="bold"
          htmlFor="preferences-email"
        >
          {t('editPreferences.emailAddress')}
        </Label>
        <TextInput
          id="preferences-email"
          data-testid="preferences-email"
          aria-label="preferences-email"
          type="email"
          value={email ? email : ''}
          onChange={e => {
            setEmail(e.target.value)
            if (errorMessageMap.emailAddress) {
              setErrorMessageMap({
                ...errorMessageMap,
                emailAddress: ''
              })
            }
          }}
        />
        {errorMessageMap && errorMessageMap.emailAddress && (
          <ValidationError validationError={errorMessageMap.emailAddress} />
        )}
        {errorMessageMap && errorMessageMap.general && (
          <ValidationError validationError={errorMessageMap.general} />
        )}
        <PrimaryButton
          type="button"
          my={2}
          disabled={submitting}
          onClick={() => {
            validateAndSubmitForm(sms, email)
          }}
        >
          {t('editPreferences.save')}
        </PrimaryButton>
        <InternalLink textAlign="center" to="/preferences">
          {t('editPreferences.cancel')}
        </InternalLink>
      </FormContent>
    </LayoutWrapper>
  )
}
