import {IconStyles} from "../../types";
import {colors} from "./scalar";

export const icons: IconStyles = {
  primary: {
    color: colors.primaryGreen,
    label: 'PrimaryIcon'
  },
  secondary: {
    color: colors.grey,
    label: 'SecondaryIcon'
  }
}