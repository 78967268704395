import React from 'react'
import { P, PProps } from './P'
import { Message, MProps } from './Message'

export const Text: React.FC<PProps> = props => (
  <P as="p" textStyle="base" {...props} />
)

export const Heading: React.FC<PProps> = props => (
  <Text textStyle="heading" as="h2" mb={3} textAlign="center" {...props} />
)

export const Subheading: React.FC<PProps> = props => (
  <Text textStyle="subheading" as="h4" mb={2} {...props} />
)

export const MediumHeading: React.FC<PProps> = props => (
  <Text textStyle="mediumHeading" as="h3" {...props} />
)

export const XLHeading: React.FC<PProps> = props => (
  <Text textStyle="xlHeading" as="h1" {...props} />
)

export const ErrorHeading: React.FC<PProps> = props => (
  <Text textStyle="errorHeading" as="h2" {...props} />
)

export const LeftHeading: React.FC<PProps> = props => (
  <Text as="h2" textStyle="heading" mb={2} textAlign="left" {...props} />
)

export const LeftTitle: React.FC<PProps> = props => (
  <Text as="h2" textStyle="spacedHeading" textAlign="left" {...props} />
)

export const SpacedHeading: React.FC<PProps> = props => (
  <Text as="h3" textStyle="mediumHeading" mb={3} {...props} />
)

export const FAQHeading: React.FC<PProps> = props => (
  <Text
    as="h2"
    textStyle="faqHeading"
    my={3}
    mb={2}
    textAlign="left"
    {...props}
  />
)

export const HCIMessage: React.FC<MProps> = props => (
  <Message textStyle='small' whiteSpace='pre-wrap' {...props} />
)
