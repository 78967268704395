import {FontAwesomeIcon, FontAwesomeIconProps} from "@fortawesome/react-fontawesome";
import {color, size, SizeProps, space, SpaceProps, variant} from "styled-system";
import React from "react";
import styled from "@emotion/styled";
import {IconVariant} from "../../styles/types";

const iconStyle = variant({ key: 'icons' })

type IconProps = Pick<FontAwesomeIconProps, 'color' | 'icon'>
& SizeProps
& Pick<SpaceProps, 'pt'>
& {
  variant: IconVariant
}

export const Icon: React.FC<IconProps> = styled(FontAwesomeIcon)(
  {},
  space,
  color,
  size,
  iconStyle,
)