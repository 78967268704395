const tuple = <T extends string[] | number[]>(...args: T) => args

export const colors = {
  black: '#242627',
  darkGrey: '#5E5E5E',
  grey: '#AFAFAF',
  lightGrey: '#D7D7D7',
  veryLightGrey: '#EBEFEB',
  offWhite: '#F6F6F6',
  white: '#FFFFFF',
  darkGreen: '#3B7C50',
  primaryGreen: '#49915D',
  mediumGreen: '#56BE75',
  lightGreen: '#B9E6AF',
  veryLightGreen: '#E5F2DC',
  wintergreen: '#EBEFEB',
  red: '#D50035',
  purple: '#743B72',
  alertIcon: '#242627'
}

export const fonts = {
  sans: "'Open Sans', sans-serif"
}

export const fontWeights = {
  light: 400,
  bold: 600
}

export const fontSizes = {
  tiny: '0.75rem',
  small: '0.875rem',
  normal: '1rem',
  aBitBigger: '1.125rem',
  somewhatBig: '1.25rem',
  large: '1.5rem',
  xlarge: '2rem',
  xxlarge: '3rem',
  xxxlarge: '4rem'
}

export const heights = tuple('60px')

export const lineHeights = tuple(1, 1.4, 1.6, 2, 2.5)

export const space = tuple(0, 10, 15, 20, 30, 50, 60)
