import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Flex } from './Library/Flex'

export const Loading: React.FC = () => (
  <Flex
    flex="1 1 auto"
    alignItems="center"
    justifyContent="center"
  >
    <FontAwesomeIcon
      size="2x"
      spin
      icon={faSpinner}
    />
  </Flex>
)
