/* eslint-disable jsx-a11y/anchor-has-content */

import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { withTheme } from "emotion-theming";
import { Flex } from '../../components/Library/Flex'
import { Fixed } from '../../components/Library/Fixed'
import { Text } from '../../components/Library/Typography'

const AlertCircle: React.FC = withTheme(({theme}) => {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={theme.colors.alertIcon}
      d="M10 2C11.4194 2 12.7742 2.3871 14 3.09677C15.2258 3.80645 16.1935 4.77419 16.9032 6C17.6129 7.22581 18 8.58065 18 10C18 11.4516 17.6129 12.7742 16.9032 14C16.1935 15.2258 15.2258 16.2258 14 16.9355C12.7742 17.6452 11.4194 18 10 18C8.54839 18 7.22581 17.6452 6 16.9355C4.77419 16.2258 3.77419 15.2258 3.06452 14C2.35484 12.7742 2 11.4516 2 10C2 8.58065 2.35484 7.22581 3.06452 6C3.77419 4.77419 4.77419 3.80645 6 3.09677C7.22581 2.3871 8.54839 2 10 2ZM10 16.4516C11.1613 16.4516 12.2258 16.1613 13.2258 15.5806C14.1935 15 15 14.2258 15.5806 13.2258C16.1613 12.2581 16.4516 11.1613 16.4516 10C16.4516 8.83871 16.1613 7.77419 15.5806 6.77419C15 5.80645 14.1935 5 13.2258 4.41935C12.2258 3.83871 11.1613 3.54839 10 3.54839C8.83871 3.54839 7.74194 3.83871 6.77419 4.41935C5.77419 5 5 5.80645 4.41935 6.77419C3.83871 7.77419 3.54839 8.83871 3.54839 10C3.54839 11.1613 3.83871 12.2581 4.41935 13.2258C5 14.2258 5.77419 15 6.77419 15.5806C7.74194 16.1613 8.83871 16.4516 10 16.4516ZM11.3548 13.0968C11.3548 12.7419 11.1935 12.4194 10.9355 12.1613C10.6774 11.9032 10.3548 11.7419 10 11.7419C9.6129 11.7419 9.29032 11.9032 9.03226 12.1613C8.77419 12.4194 8.64516 12.7419 8.64516 13.0968C8.64516 13.4839 8.77419 13.8065 9.03226 14.0645C9.29032 14.3226 9.6129 14.4516 10 14.4516C10.3548 14.4516 10.6774 14.3226 10.9355 14.0645C11.1935 13.8065 11.3548 13.4839 11.3548 13.0968ZM8.74194 6.29032C8.70968 6.16129 8.74194 6.06452 8.80645 6C8.87097 5.93548 9 5.87097 9.12903 5.87097H10.871C11 5.87097 11.0968 5.93548 11.1613 6C11.2258 6.06452 11.2581 6.16129 11.2581 6.29032L11.0645 10.6774C11.0323 10.7742 10.9677 10.871 10.9032 10.9355C10.8387 11 10.7419 11.0323 10.6774 11.0323H9.32258C9.22581 11.0323 9.12903 11 9.06452 10.9355C9 10.871 8.93548 10.7742 8.93548 10.6774L8.74194 6.29032Z"/>
  </svg>
})

interface Props {
  clinic: { clinicId: string | undefined; clinicName: string | undefined }
}
export const VerificationError: React.FC<Props> = ({ clinic }) => {
  const clinicPath = `/your-clinic/${clinic.clinicId}`
  const { t } = useTranslation()
  return (
    <Fixed variant="errorBanner" data-testid="verification-error">
      <Flex alignItems="center">
        <AlertCircle />
        &nbsp;
        <Text textStyle="bold">{t('verify.errorTitle')}</Text>
      </Flex>
      <Text textStyle="normal">
        <Trans
          i18nKey="verify.errorMessage"
          components={[
            <a
              key="contact_clinic"
              href={clinicPath}
              style={{ color: '#242627' }}
            ></a>
          ]}
        />
      </Text>
    </Fixed>
  )
}
