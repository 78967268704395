import React from 'react'
import { Layout } from './Layout'
import { LoadAllContentTickets } from '../../dataProviders/LoadAllContentTickets'

export const ListMessages: React.StatelessComponent = () => (
  <LoadAllContentTickets
    render={contentTickets => (
      <Layout instructions={contentTickets.content_requests} />
    )}
  />
)
