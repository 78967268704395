export const getQueryParam = (
  paramName: string,
  search: string = window.location.search
) => {
  const urlParams = search.match(new RegExp(`${paramName}=([a-zA-Z0-9_+%-]+)`))

  if (urlParams && urlParams.length >= 2) {
    return urlParams[1]
  }

  return undefined
}
