import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useContext
} from 'react'
import {useTranslation, Trans} from 'react-i18next'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner, faLock} from '@fortawesome/free-solid-svg-icons'
import {Checkbox} from 'ui-components/dist'
import {Flex} from '../../components/Library/Flex'
import {VerificationError} from './VerificationError'
import {
  FormContent,
  Label,
  TextInput,
  PrimaryButton,
  Content,
  InlineInternalLink
} from '../../components/Library'
import {DateOfBirthInput} from '../../components/DateOfBirthInput'
import {Footer} from '../../components/Footer'
import {MediumHeading, Text} from '../../components/Library/Typography'
import {LogoHeader} from '../../components/LogoHeader'
import {ValidationError} from './ValidationError'
import {ZipCode} from '../../components/ZipCode'
import {BirthDate} from './useFormState'
import {Store} from '../../state/Provider'
import {PageContent} from "../../components/PageContent";

export interface LayoutProps {
  firstName: string
  setFirstName: (n: string) => void
  lastName: string
  setLastName: (n: string) => void
  dateOfBirth: BirthDate
  setBirthdate: Dispatch<SetStateAction<BirthDate>>
  zipcode: string
  setZipcode: (n: string) => void
  rememberMe: boolean
  setRememberMe: (n: boolean) => void
  verifyBirthdateOnly: boolean
  submit: (e: SyntheticEvent) => void
  hasErrors: boolean
  validationError: string
  setValidationError: (n: string) => void
  isSubmitting: boolean
}

export const Layout: React.StatelessComponent<LayoutProps> = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  dateOfBirth,
  setBirthdate,
  zipcode,
  setZipcode,
  rememberMe,
  setRememberMe,
  verifyBirthdateOnly,
  submit,
  hasErrors,
  validationError,
  setValidationError,
  isSubmitting
}) => {
  const {state} = useContext(Store)
  const {t} = useTranslation()
  const clinicPath = `/your-clinic/${state.clinicId}`
  return (
    <>
      <LogoHeader to={clinicPath} column>
        <>
          <Content
            textAlign="center"
            alignItems="center"
            marginTop="1rem"
            overflow="hidden"
          >
            <Text>{t('verify.digitalCompanion')}</Text>
            <InlineInternalLink to={clinicPath}>
              {state.clinicName}
            </InlineInternalLink>
          </Content>
        </>
      </LogoHeader>

      <PageContent pageVariant="form" banner={{title: t('start.welcome')}}>
        <Content
          px={25}
          pt={3}
          pb={5}
          alignItems="center"
          width={1}
        >
          <Text mb={1} textStyle="normalSpaced" textAlign="center">
            {verifyBirthdateOnly
              ? t('verify.verifyBirthdate')
              : t('verify.verifyPatientInformation')}
          </Text>
          {hasErrors && <VerificationError clinic={state}/>}
          <FormContent pt={3} maxWidth={325} flex={'0 0 auto'}>
            {!verifyBirthdateOnly && (
              <>
                <Label htmlFor="firstname">{t('verify.firstName')}</Label>
                <TextInput
                  id="firstname"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
                <Label htmlFor="lastname">{t('verify.lastName')}</Label>
                <TextInput
                  id="lastname"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </>
            )}
            {verifyBirthdateOnly ? (
              <>
                <DateOfBirthInput
                  id="dateOfBirth"
                  dateOfBirth={dateOfBirth}
                  setBirthdate={setBirthdate}
                  setValidationError={setValidationError}
                  hasError={validationError.length > 0 || hasErrors}
                />
              </>
            ) : (
              <Flex mb={3} flexDirection="row">
                <Flex flexDirection="column" width={2 / 3} pr={4}>
                  <DateOfBirthInput
                    id="dateOfBirth"
                    dateOfBirth={dateOfBirth}
                    setBirthdate={setBirthdate}
                    setValidationError={setValidationError}
                    hasError={!!(validationError || hasErrors)}
                  />
                </Flex>
                <Flex flexDirection="column" width={1 / 3}>
                  <ZipCode
                    setValidationError={setValidationError}
                    setZipcode={setZipcode}
                    validationError={validationError}
                    zipcode={zipcode}
                  />
                </Flex>
              </Flex>
            )}

            {validationError && (
              <Flex>
                <ValidationError validationError={validationError}/>
              </Flex>
            )}
            <PrimaryButton
              onClick={submit}
              type="submit"
              formNoValidate
              data-testid="verify-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <FontAwesomeIcon size="1x" spin color="white" icon={faSpinner}/>
              ) : (
                <>
                  <span>
                    <FontAwesomeIcon
                      size="xs"
                      color="white"
                      icon={faLock}
                      style={{
                        verticalAlign: '3px',
                        lineHeight: 0,
                        marginRight: '.5rem',
                        fontSize: '.875rem'
                      }}
                    />
                  </span>
                  <span style={{lineHeight: 0}}>{t('verify.verify')}</span>
                </>
              )}
            </PrimaryButton>
            <div style={{marginTop: '5px'}}>
              <Checkbox
                name={'rememberMe'}
                text={t('verify.rememberMe')}
                checked={rememberMe}
                onChange={() => {
                  setRememberMe(!rememberMe)
                }}
              />
            </div>
          </FormContent>
          <Content width={1} overflow="hidden">
            <hr
              style={{
                height: '1px',
                width: '100%',
                color: '#D7D7D7',
                backgroundColor: '#D7D7D7',
                border: 'none',
                margin: '20px 0'
              }}
            />

            <MediumHeading>{t('verify.aboutTitle')}</MediumHeading>
            <Text>
              <span>{t('verify.aboutDescription')} </span>
              <span>
                <Trans
                  i18nKey="verify.privacyPolicy"
                  components={[
                    <InlineInternalLink key="terms" to="/terms"/>,
                    <InlineInternalLink key="privacy" to="/privacy"/>
                  ]}
                />
              </span>
            </Text>
          </Content>
        </Content>
      </PageContent>
      <Footer/>
    </>
  )
}
