import React, { useState, useEffect } from 'react'

function useDurationTimer(duration: number) {
  const [isVisible, setVisible] = useState(true)

  useEffect(() => {
    let timer: NodeJS.Timeout
    function onStart() {
      timer = setTimeout(() => {
        setVisible(false)
      }, duration)
    }

    onStart()
    return () => clearTimeout(timer)
  }, [duration])

  return isVisible
}

interface Props {
  children: React.ReactNode
}

export function FlashMessage({ children }: Props): JSX.Element | null {
  const isVisible = useDurationTimer(5000)

  return isVisible ? <>{children}</> : null
}
