import React from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from "emotion-theming";
import { Flex } from '../../components/Library/Flex'
import { ErrorHeading, Text } from '../../components/Library/Typography'
import { Content, ExternalLink } from '../../components/Library'
import { ReactComponent as SecurityErrorIcon } from '../../svg/icon-security-error.svg'
import { Footer } from '../../components/Footer'
import { LogoHeader } from '../../components/LogoHeader'
import {ReactComponent as LockIcon} from "../../svg/icon-lock.svg";
import {PageContent} from "../../components/PageContent";


interface Props {
  clinic: {
    clinicName: string | undefined
    clinicPhoneNumber: string | undefined
  }
}

export const Layout: React.FC<Props> = withTheme(({ theme }) => {
  const { t } = useTranslation()
  return (
    <>
      <LogoHeader />
      <PageContent pageVariant='error' banner={theme.pageBannerStyle ==='curved' && {circleColor: theme.colors.yellow, iconPosition: 'center', title: '', icon: <LockIcon />}}>
        <Content mr="auto" ml="auto" px={25} bg="white" alignItems="stretch" overflow="hidden">
          <Flex p={3} flexDirection="column" alignItems="center">
            {theme.pageBannerStyle !== 'curved' && <SecurityErrorIcon height={62} width={62} /> }
            <ErrorHeading mx={6} my={2} textAlign="center">
              {t('tooManyLoginAttempts.oops')}
            </ErrorHeading>
            <Text textAlign="center" mb={3} data-testid="problem-message">
              {t('tooManyLoginAttempts.errorMessage')}
            </Text>
            <Text textAlign="center">
              {t('tooManyLoginAttempts.contactClinic')}
            </Text>
            <hr
              style={{
                height: '1px',
                width: '100%',
                color: '#D7D7D7',
                backgroundColor: '#D7D7D7',
                border: 'none',
                margin: '20px 0'
              }}
            />
            <Text mx={6} textStyle="bold" textAlign="center">
              {t('tooManyLoginAttempts.nc')}{' '}
              {t('tooManyLoginAttempts.customerSupport')}
            </Text>
            <Text>{t('tooManyLoginAttempts.day')}</Text>
            <Text mb={3}>{t('tooManyLoginAttempts.time')}</Text>
            <Text>{t('tooManyLoginAttempts.phone')}</Text>
            <ExternalLink mb={3} href="tel:1-800-925-4456">
              1-800-925-4456
            </ExternalLink>
            <Text>{t('tooManyLoginAttempts.email')}</Text>
            <Text>
              <ExternalLink href="mailto:support@navigatingcare.com">
                support@navigatingcare.com
              </ExternalLink>
            </Text>
          </Flex>
        </Content>
      </PageContent>
      <Footer />
    </>
  )
})
