import css from '@emotion/css'

export default css`
  html,
  body {
    height: 100%;
    font-family: sans-serif;
    font-size: 16px;
    overscroll-behavior: none;
  }

  body {
    margin: 0;
    padding: 0;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    overscroll-behavior: none;
  }

  .content {
    flex: 1 0 auto;
    padding: 10px;
    overflow: scroll;
  }

  .footer {
    flex-shrink: 0;
    text-align: center;
    background-color: tomato;
    color: white;
  }
`
