import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only'

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '71b4badd-d170-49bd-afce-491be7bd27b1',
  clientToken: 'pub28f8854f91d9e47757c8561ac266840c',
  site: 'datadoghq.com',
  service: 'px-client',
  env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
  version: process.env.REACT_APP_DD_VERSION,
  resourceSampleRate:
    Number(process.env.REACT_APP_DD_RUM_SAMPLE_PERCENT) || 100,
  sampleRate: Number(process.env.REACT_APP_DD_RUM_SAMPLE_PERCENT) || 100,
  allowedTracingOrigins: [
    /https:\/\/.*\.nc-acceptance\.com/,
    /https:\/\/.*\.navigatingcare\.com/,
    'https://nav.care',
  ],
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
