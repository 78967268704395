import { jsonRequest } from '../../api/jsonRequest'

export async function updatePatientConsent(consent: boolean) {
  return jsonRequest(`/api/patient-consent`, {
    method: 'PUT',
    body: JSON.stringify({digital_education_activated_at: consent})
  })
}

export async function getPatientConsent() {
  return jsonRequest(`/api/patient-consent`, {
    method: 'GET',
  })
}

export function getPatientConsentContents(received_locale: string) {
  return jsonRequest(`/api/patient-consent/consent-content/${received_locale}`, {
    method: 'GET',
  })
}
