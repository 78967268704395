import React from 'react'
import { GetDataError } from 'restful-react'
import { Redirect } from 'react-router-dom'
import { ErrorMessage } from './ErrorMessage'

interface Props {
  urlToken?: string
  error?: GetDataError<unknown>
}

interface UnacceptedTerms {
  status: 'unaccepted_terms'
}

interface FailedAttempts {
  status: 'too_many_failed_attempts'
}

function termsUnaccepted(error: GetDataError<unknown>) {
  const data = (error as GetDataError<UnacceptedTerms>).data as UnacceptedTerms

  return data && data.status === 'unaccepted_terms'
}

function tooManyLoginAttempts(error: GetDataError<unknown>) {
  const data = (error as GetDataError<FailedAttempts>).data as FailedAttempts

  return data && data.status === 'too_many_failed_attempts'
}

function unauthorized(error: GetDataError<unknown>) {
  return error.status === 401
}

export const Error: React.FC<Props> = ({ error, urlToken }) => {
  let redirect = ''
  const params = new URLSearchParams(window.location.search)
  if (error) {
    if (termsUnaccepted(error)) {
      redirect = `/accept-terms`
      if (urlToken) {
        params.set('urlToken', urlToken)
      }
    } else if (tooManyLoginAttempts(error)) {
      redirect = '/verification-error'
    } else if (unauthorized(error)) {
      localStorage.removeItem('authenticated')
      redirect = `/verify/${urlToken || ''}`
    }
  }

  return redirect ? (
    <Redirect to={{ pathname: redirect, search: params.toString() }} />
  ) : (
    <ErrorMessage code={(error && error.status) || 0} />
  )
}
