import CSS from "csstype";
import {colors, fonts, space} from './scalar'
import { FixedStyles } from '../../types'

export const baseFixedStyle = {
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: colors.white,
  padding: `${space[3]}px 25px`,
  width: '100%',
  flex: '0 0 auto',
  flexShrink: 0,
  zIndex: 1
}

const basePageBanner: CSS.StandardProperties = {
  width: "100%",
  height: "99px",
  minHeight: "99px",
  position: "relative",
  backgroundColor: colors.bannerBlue,
  overflow: "hidden",
  color: colors.white,
  fontFamily: fonts.mukta
}

const basePageContent: CSS.StandardProperties = {
  flex:"1 0 auto",
  alignItems:"flex-start",
  alignSelf: "center",
  flexDirection:"column",
  margin: '0 auto',
  maxWidth: 'unset',
  width:'100%',
  backgroundColor: colors.white
}

export const fixed: FixedStyles = {
  footer: {
    ...baseFixedStyle,
    alignItems: 'stretch',
    flexDirection: 'column',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1), 0px -5px 10px rgba(0, 0, 0, 0.05)',
    label: 'Footer'
  },
  header: {
    ...baseFixedStyle,
    flex: "0 0 auto",
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.05)',
    label: 'Header'
  },
  errorPageContent: {
    ...basePageContent,
    label: 'ErrorPageContent'
  },
  infoPageContent: {
    ...basePageContent,
    label: 'InfoPageContent'
  },
  formPageContent: {
    ...basePageContent,
    backgroundColor: colors.lightGrey,
    label: 'FormPageContent'
  },
  errorPageBanner: {
    ...basePageBanner,
    backgroundColor: colors.lightGrey,
    label: 'ErrorPageBanner'
  },
  infoPageBanner: {
    ...basePageBanner,
    label: 'InfoPageBanner'
  },
  formPageBanner: {
    ...basePageBanner,
    label: 'FormPageBanner'
  },
  errorBanner: {
    backgroundColor: "#FBE6EB",
    padding: `${space[2]}px`,
    flexDirection: "column",
    borderRadius: '3px',
    border: '2px solid #FFFFFF',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05), 0px 10px 10px rgba(0, 0, 0, 0.1)',
    label: 'ErrorBanner',
  }
}
