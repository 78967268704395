import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LinkIcon } from '../svg/icon-link.svg'
import { ReactComponent as HelpIcon } from '../svg/icon-help.svg'
import { Flex } from '../components/Library/Flex'
import { Box } from '../components/Library/Box'
import {
  BorderBottom,
  Content, InlineExternalLink
} from '../components/Library'
import { Text } from '../components/Library/Typography'
import { Footer } from '../components/Footer'
import { LogoHeader } from '../components/LogoHeader'

export const Welcome: React.FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <LogoHeader />
      <Content px={25} py={3}>
        <BorderBottom flexDirection="column" pb={3}>
          <LinkIcon width="60" height="60" />
          <Box>
            <Text my={1} textAlign="center" textStyle="heading">
              {t('welcome.signIn')}
            </Text>
            <Text textAlign="center" textStyle="normal">
              {t('welcome.signInText')}
            </Text>
          </Box>
        </BorderBottom>
        <Flex width="100%" alignItems="center" flexDirection="column" py={3}>
          <HelpIcon width="60" height="60" />
          <Box>
            <Text my={1} textAlign="center" textStyle="heading">
              {t('welcome.needHelp')}
            </Text>
            <Text textAlign="center" textStyle="normal">
              {t('welcome.contactClinic')}
              <InlineExternalLink
                href="https://www.navigatingcare.com/patient/contact-us.html"
                target="_blank"
              >
                {t('welcome.customerSupport')}
              </InlineExternalLink>.
            </Text>
          </Box>
        </Flex>
      </Content>
      <Footer />
    </>
  )
}
