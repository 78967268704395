import React, { useContext } from 'react'
import { GetDataError } from 'restful-react'
import { Context as AnalyticsContext } from '../analytics'


interface Props {
  urlToken?: string
  error: GetDataError<unknown>
}


export const ErrorAnalytics: React.FC<Props> = ({ error }) => {

  const analytics = useContext(AnalyticsContext)
  const { message } = error
  const params = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    event_label: window.location.href,
    value: message
  }
  analytics.error('error', params)  

  return null
}
