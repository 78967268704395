import React, { useState } from 'react'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import {Icon} from "./Library/Icon";

export const LanguageSelect = () => {
  const { t } = useTranslation()
  const params = new URLSearchParams(window.location.search)
  const [language, setLanguage] = useState(params.get('language') || 'en')

  const onLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget
    setLanguage(value)
    params.set('language', value)
    i18next.changeLanguage(value)
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${params.toString()}`
    window.history.pushState({ path: newurl }, '', newurl)
  }

  return (
    // For some reason these styles are only working when in-lined
    <div
      className="select-wrapper"
      style={{ marginLeft: 'auto', display: 'flex' }}
    >
      <select
        aria-label="language"
        className="language-select"
        value={language}
        onChange={e => onLanguageChange(e)}
      >
        <option value="en">{t('footer.englishSelect')}</option>
        <option value="es">{t('footer.spanishSelect')}</option>
      </select>
      <span className="language-select-icon-wrapper">
        <Icon variant="primary" icon={faAngleDown}  />
      </span>
    </div>
  )
}
