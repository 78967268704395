import React from 'react'
import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import {
  borderColor,
  borders,
  color,
  fontFamily,
  fontSize,
  lineHeight,
  space,
  BorderColorProps,
  ColorStyleProps,
  LineHeightProps,
  FontFamilyProps,
  SpaceProps,
  FontSizeProps,
  BorderProps,
  textStyle,
  TextStyleProps, variant,
} from 'styled-system'
import {InputVariant} from "../../styles/types";

export type InputProps = BorderColorProps &
BorderProps &
LineHeightProps &
FontFamilyProps &
FontSizeProps &
ColorStyleProps &
SpaceProps &
TextStyleProps & {
  variant?: InputVariant
}

const inputStyle = variant({ key: 'inputs' })

export const Input: React.FC<InputProps> = styled('input', {
  shouldForwardProp: (prop: string) =>
    isPropValid(prop) &&
    prop !== 'fontSize' &&
    prop !== 'fontFamily' &&
    prop !== 'color'
})(
  {
    appearance: 'none',
    boxSizing: 'border-box',
    borderRadius: 3
  },
  borders,
  borderColor,
  lineHeight,
  fontFamily,
  fontSize,
  color,
  space,
  textStyle,
  inputStyle
) as React.FC<InputProps>
Input.displayName = 'Input'

export type LabelProps = React.DetailedHTMLProps<
React.LabelHTMLAttributes<HTMLLabelElement>,
HTMLLabelElement
>
