import css from '@emotion/css'

export default css`
  .language-select {
    background-color: rgb(235, 239, 235);
    font-size: 0.875rem;
    float: right;
    border: none;
    color: rgb(36, 38, 39);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: 0;
    box-shadow: none;
  }

  /* For IE <= 11 */
  .language-select::-ms-expand {
    display: none;
  }

  .language-select-icon-wrapper {
    margin-left: 2px;
  }
`
