import React, {useEffect} from 'react'

import { BrowserRouter as Router } from 'react-router-dom'

import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import { ThemeProvider } from './styles/themes/provider'
import { Routes } from './Routes'
import { ThemeId} from './styles/themes'
import { Provider as StateProvider } from './state/Provider'
import { Provider as AnalyticsProvider } from './analytics'
import i18n from './i18n'

export const App: React.FC = () => {
  const params = new URLSearchParams(window.location.search)

  useEffect(() => {
    const lng = params.get('language') === 'es' ? 'es' : 'en'
    i18next.changeLanguage(lng)
  }, [params])

  return (
    <Router>
      <StateProvider>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider themeId={params.get('theme') as ThemeId}>
            <AnalyticsProvider>
              <Routes />
            </AnalyticsProvider>
          </ThemeProvider>
        </I18nextProvider>
      </StateProvider>
    </Router>
  )
}
