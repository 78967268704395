import React from 'react'
import { useTranslation } from 'react-i18next'

import { ExternalLink, GroupingBox, Image } from '../../components/Library'
import { Flex } from '../../components/Library/Flex'
import { Text } from '../../components/Library/Typography'
import { LayoutWrapper } from '../../layouts/LayoutWrapper'
import { Clinic } from '../../types/models'

interface Props {
  clinic: Clinic
}

export const Layout: React.StatelessComponent<Props> = ({ clinic }) => {
  const { t } = useTranslation()
  const clinicGmapsUrl = `https://www.google.com/maps/search/?api=1&query=${[
    clinic.streetAddress1,
    clinic.streetAddress2,
    clinic.city,
    clinic.state,
    clinic.postalCode
  ]
    .filter(o => o !== null)
    .map(s => s && encodeURIComponent(s))
    .join(',')}`
  const clinicTelHref = `tel:${clinic.phoneNumber}`
  return (
    <LayoutWrapper title={t('yourClinic.title')}>
      <Flex alignItems="center" flexDirection="column" width="100%">
        <GroupingBox textAlign="center">
          <Flex alignItems="center" flexDirection="column" width="100%">
            <Text data-testid="clinic-name">{clinic.name}</Text>

            {clinic.streetAddress1 && (
              <Text data-testid="clinic-address-line-1">{clinic.streetAddress1}</Text>
            )}
            {clinic.streetAddress2 && (
              <Text data-testid="clinic-address-line-2">{clinic.streetAddress2}</Text>
            )}
            {clinic.city && (
              <Text data-testid="clinic-city">
                {/* eslint-disable-next-line react/jsx-no-literals */}
                {clinic.city}, {clinic.state} {clinic.postalCode}
              </Text>
            )}

            <ExternalLink
              target="_blank"
              href={clinicGmapsUrl}
              data-testid="clinic-gmaps-url"
            >
              {t('yourClinic.viewOnMap')}
            </ExternalLink>
          </Flex>
        </GroupingBox>
        <GroupingBox textAlign="center">
          <Flex alignItems="center" flexDirection="column" width="100%">
            <Text>{t('yourClinic.phone')}</Text>
            <ExternalLink
              href={clinicTelHref}
              data-testid="clinic-phone"
            >
              {clinic.phoneNumber}
            </ExternalLink>
          </Flex>
        </GroupingBox>
        {clinic.logoUrl && (
          <Image src={clinic.logoUrl} alt="Clinic Logo" width="225px" />
        )}
      </Flex>
    </LayoutWrapper>
  )
}
