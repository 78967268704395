import React from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import styled from '@emotion/styled'
import { Flex } from './Flex'
import { Text } from './Typography'
import {Icon} from "./Icon";

interface Props {
  icon: IconDefinition
  message: string
}

const Card = styled(Flex)({
  borderRadius: '3px'
})

Card.displayName = 'Card'

export const FlashCard = ({ message, icon }: Props) => (
  <Card
    data-testid="flash-card"
    p={2}
    mb={3}
    alignItems="flex-start"
    bg="veryLightGreen"
  >
    <Icon color="primaryGreen" variant="primary" pt="4px" icon={icon} />
    <Text pl={1} textAlign="left">
      {message}
    </Text>
  </Card>
)
