import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { Redirect } from 'react-router-dom'
import { withTheme } from "emotion-theming";
import { LayoutWrapper } from '../../layouts/LayoutWrapper'
import { LeftHeading, Text } from '../../components/Library/Typography'
import { ExternalLink } from '../../components/Library'


interface Props {
  urlToken: string
  clinicName: string | undefined
}

export const Layout: React.FC<Props> = withTheme(({ urlToken, clinicName, theme }) => {
  const { t } = useTranslation()
  const [goBack, setGoBack] = useState<boolean>(false)
  if (goBack) {
    return (
      // accept-terms?urlToken=McP42sWn
      // eslint-disable-next-line react/jsx-no-literals
      <Redirect to={`/accept-terms?urlToken=${urlToken}`}></Redirect>
    )
  }

  return (
    <LayoutWrapper {...theme.pageBannerStyle === 'curved'? {
      title: t('termsDeclined.thankYou'),
      showBanner: true
    }: {}}>
      {theme.pageBannerStyle !== 'curved' && <LeftHeading mb={3}>{t('termsDeclined.thankYou')}</LeftHeading>}
      <Text>{t('termsDeclined.subTitle')}</Text>
      <ExternalLink onClick={() => {setGoBack(true)}} mb={4} lineHeight={3}>
        <FontAwesomeIcon
          icon={faChevronCircleLeft}
          style={{ paddingRight: '0.5rem' }}
        />
        {t('termsDeclined.backToTerm')}
      </ExternalLink>
      <Text>{t('termsDeclined.sincerely')}</Text>
      <Text>{t('termsDeclined.yourCare')}</Text>
      <Text textStyle="bold" data-testid="clinic-name">
        {clinicName}
      </Text>
    </LayoutWrapper>
  )
})
