import { LinkProps as InternalLinkProps } from 'react-router-dom'
import { LinkVariant } from '../../styles/types'
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import { space, textAlign, width, variant } from 'styled-system'
import { PProps } from './P'

export type LinkProps = PProps &
  Pick<
    React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    'role' | 'onClick' | 'onKeyPress' | 'href'
  > & { linkStyle?: LinkVariant }

const linkStyle = variant({
  key: 'links',
  prop: 'linkStyle'
})

export const A: React.FC<LinkProps | InternalLinkProps> = styled('a', {
  shouldForwardProp: (prop: string) => isPropValid(prop) && prop !== 'linkStyle'
})(
  { label: 'Anchor', textDecoration: 'underline', cursor: 'pointer' },
  textAlign,
  width,
  space,
  linkStyle
)
A.displayName = 'Anchor'
