import React, { ComponentProps } from 'react'
import {
  FontFamilyProps,
  SpaceProps,
  LineHeightProps,
  FontSizeProps,
  fontFamily,
  space,
  lineHeight,
  fontSize,
  style,
  color
} from 'styled-system'
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'

const listStyle = style({
  prop: 'listStyle',
  cssProperty: 'listStyle',
  key: 'listStyle'
})

const listStyleType = style({
  prop: 'listStyleType',
  cssProperty: 'listStyleType',
  key: 'listStyleType'
})

const listStylePosition = style({
  prop: 'listStylePosition',
  cssProperty: 'listStylePosition',
  key: 'listStylePosition'
})

export type ULProps = {
  listStyle?: string
  listStyleType?: string
} & FontFamilyProps &
  SpaceProps &
  LineHeightProps &
  FontSizeProps &
  ComponentProps<'ul'>

export const UL: React.FC<ULProps> = styled('ul', {
  shouldForwardProp: (prop: string) => isPropValid(prop) && prop !== 'fontSize'
})(
  { label: 'UL' },
  fontFamily,
  space,
  lineHeight,
  fontSize,
  listStyle,
  listStyleType,
  listStylePosition,
  color
)
UL.displayName = 'UL'

export type LIProps = SpaceProps & ComponentProps<'li'>
export const LI = styled.li({ label: 'LI' }, space)
LI.displayName = 'LI'
