import React, { createContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { ClientInterface } from '../types/analytics'
import { client } from './Client'

// eslint-disable-next-line import/no-mutable-exports
export let Context: React.Context<ClientInterface>

export const Provider = withRouter(({ children }) => {
  Context = createContext(client)
  useEffect(() => client.pageview(window.location.href))
  return <Context.Provider value={client}>{children}</Context.Provider>
})
