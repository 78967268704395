import css from '@emotion/css'
import {colors} from './scalar'

export default css`
  input {
    ::placeholder {
      color: ${colors.darkGrey};
    }
  }
  
  /* override checkbox check color defined in UI components */
  input[type='checkbox'] + label:after {
      color: ${colors.primaryBlue} !important;
  }

  input[type='date'] {
    ::-webkit-datetime-edit-month-field {
      text-transform: uppercase;
      color: ${colors.darkGrey};
    }
    ::-webkit-datetime-edit-day-field {
      text-transform: uppercase;
      color: ${colors.darkGrey};
    }
    ::-webkit-datetime-edit-year-field {
      text-transform: uppercase;
      color: ${colors.darkGrey};
    }
    ::-webkit-calendar-picker-indicator {
      background: transparent;
    }
    ::-webkit-datetime-edit-text {
      color: ${colors.darkGrey};
    }
    ::-webkit-inner-spin-button {
      display: none;
    }
  }

  input[type='date'].notempty,
  input[type='date']:focus {
    ::-webkit-datetime-edit-month-field {
      color: ${colors.black};
    }
    ::-webkit-datetime-edit-day-field {
      color: ${colors.black};
    }
    ::-webkit-datetime-edit-year-field {
      color: ${colors.black};
    }
    ::-webkit-datetime-edit-text {
      color: ${colors.black};
    }
  }
`
