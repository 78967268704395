import React from 'react'
import { useTranslation } from 'react-i18next'
import { Instruction } from '../../types/models'
import { scrollToTop } from '../../browser/scrollToTop'
import { LayoutWrapper } from '../../layouts/LayoutWrapper'
import { InternalLink, GroupingBox } from '../../components/Library'
import { LeftTitle, HCIMessage } from '../../components/Library/Typography'
import { FlashMessage } from '../../components/FlashMessage'
import { FlashCard } from '../../components/Library/FlashCard'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

interface Props {
  instruction: Instruction
  welcome: boolean
  clinicName: string
}

export const Layout: React.FC<Props> = ({
  instruction,
  welcome,
  clinicName
}) => {
  const { t } = useTranslation()
  const sanitizeHTML = (message: string) => {
    return {
      __html: message 
    }
  }
  return (
    <LayoutWrapper>
      {welcome && (
        <FlashMessage>
          <FlashCard
            icon={faCheckCircle}
            message={t('viewMessages.successMessage', { clinicName })}
          />
        </FlashMessage>
      )}
      <InternalLink to="/homecare-instructions">
        {t('viewMessages.messages')}
      </InternalLink>
      <LeftTitle mt={2} mb={2} data-testid="instruction-subject">
        {instruction.subject}
      </LeftTitle>
      <GroupingBox data-testid="instruction-message" pb="0" marginBottom="50px">
        <HCIMessage dangerouslySetInnerHTML={sanitizeHTML(instruction.message)}></HCIMessage>
      </GroupingBox>
      <InternalLink to="#" onClick={scrollToTop} mb={0}>
        {t('viewMessages.backToTop')}
      </InternalLink>
    </LayoutWrapper>
  )
}
