import React from "react";
import {withTheme} from "emotion-theming";
import {Fixed} from './Library/Fixed'
import {ReactComponent as PxIcon} from '../svg/px_icon-splash.svg'
import {Text} from './Library/Typography'
import {ReactComponent as AuthorizedIcon} from '../svg/icon-authorized.svg'
import {PageBannerVariant} from "../styles/types";


export type PageBannerProps = {
  title: string
  icon?: React.ReactNode
  iconPosition?: 'right' | 'center',
  coverColor?: string
  circleColor?: string
  variant?: PageBannerVariant
}


export const PageBanner: React.FC<PageBannerProps> = withTheme(({ variant = 'infoPageBanner', title, theme, icon, circleColor, iconPosition = 'right', coverColor }) => {
  if (theme.pageBannerStyle === 'curved') {
    return <CurvedPageBanner variant={variant}
      iconPosition={iconPosition}
      circleColor={circleColor || theme.colors.purple}
      coverColor={coverColor}
      title={title} 
      icon={icon || <PxIcon alt="Banner icon" />}/>
  }

  return <DefaultPageBanner variant={variant} title={title} />
})

export const DefaultPageBanner: React.FC<PageBannerProps> = ({ variant = 'infoPageBanner', title}) =>  (
  <Fixed variant={variant}>
    <AuthorizedIcon width="60" height="60" />
    <Text as="h2" textStyle="heading">{title}</Text>
  </Fixed>
)

type CurvedPageBannerProps = PageBannerProps & {
  coverColor: string,
  circleColor: string
}

export const CurvedPageBanner: React.FC<CurvedPageBannerProps> = ({variant = 'infoPageBanner', circleColor, coverColor, icon, title, iconPosition}) => (
  <Fixed variant={variant}>
    <Text as="h1" textStyle="xlHeading" marginTop="17px" marginLeft="23px">
      {title}
    </Text>

    <div style={{
      position: "absolute",
      right: "7px",
      top: "25px",
      width: "100px",
      ...iconPosition === 'center'? {
        left: '50%',
        marginLeft: '-50px'
      }: {
        right: '7px',
      }
    }}>
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50C100 77.6142 77.6142 100 50 100C22.3858 100 0 77.6142 0 50C0
        22.3858 22.3858 0 50 0C77.6142 0 100 22.3858 100 50Z"
          fill={circleColor}
        />
        <circle cx="50" cy="50" r="37" fill={coverColor} />
      </svg>
    </div>

    <div style={{
      bottom: '-5px',
      position: 'absolute',
      width: '100%',
      height: '28px'
    }}>
      <svg width="100%" height="23" viewBox="0 0 797 23" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"><path d="M797 21.8307V23H0V22.6677C113.273 8.40995 252.402 0 402.564 0C549.763
          0 685.845 8.08138 797 21.8307Z" fill={coverColor} ></path></svg>
    </div>

    <div style={{
      alignItems: 'center',
      display: 'flex',
      height: '60px',
      justifyContent: 'center',
      position:'absolute',
      top: '43px',
      width: '60px',
      ...iconPosition === 'center'? {
        left: '50%',
        marginLeft: '-30px'
      }: {
        right: '27px',
      }
    }}>
      {icon}
    </div>
  </Fixed>
)


