import React, {useContext} from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AuthorizedIcon } from '../../svg/icon-authorized.svg'
import { Text } from '../../components/Library/Typography'
import { Footer } from '../../components/Footer'
import {
  PrimaryButtonLink,
  InternalLink,
  Content,
  InlineInternalSmallLink
} from '../../components/Library'
import { LogoHeader } from '../../components/LogoHeader'
import { Flex } from '../../components/Library/Flex'
import {Store} from "../../state/Provider";

interface Props {
  urlToken: string
  onStartButtonClick(): void
}

export const Layout: React.StatelessComponent<Props> = ({
  urlToken,
  onStartButtonClick
}) => {
  const { t } = useTranslation()
  const {state: { clinicId, clinicName }} = useContext(Store)

  return (
    <>
      <LogoHeader column>
        <Flex
          pt={1}
          flexDirection="column"
          alignItems="center"
          justifyContent="spaceBetween"
        >
          <Text textStyle="small">{t('start.helthInformation')}</Text>
          <InternalLink
            to={{
              pathname: `/your-clinic/${clinicId}`,
              search: window.location.search
            }}
            data-testid="clinic-name"
          >
            {clinicName}
          </InternalLink>
        </Flex>
      </LogoHeader>
      <Content px={25} pt={3} pb={5} alignItems="center">
        <AuthorizedIcon width="60" height="60" />
        <Text as="h2" textStyle="heading">
          {t('start.welcome')}
        </Text>
        <Text mb={3} textStyle="normalSpaced" textAlign="center">
          {t('start.verifyInformation')}
        </Text>
        <PrimaryButtonLink
          mb={3}
          to={{
            pathname: `/verify/${urlToken}`,
            search: window.location.search
          }}
          onClick={onStartButtonClick}
        >
          {t('start.start')}
        </PrimaryButtonLink>
        <Text textStyle="small">
          {t('start.privacyInformation')}{' '}
          <InlineInternalSmallLink to="/terms">
            {t('start.termsOfUse')}
          </InlineInternalSmallLink>{' '}
          {t('start.and')}{' '}
          <InlineInternalSmallLink to="/privacy">
            {t('start.privactPolicy')}
          </InlineInternalSmallLink>
        </Text>
      </Content>
      <Footer />
    </>
  )
}
