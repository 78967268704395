import React from 'react'
import styled from '@emotion/styled'
import {
  alignSelf,
  AlignSelfProps,
  color,
  flex,
  FlexProps as FlexFnProps,
  fontSize,
  FontSizeProps,
  height,
  HeightProps,
  order,
  OrderProps,
  space,
  SpaceProps,
  width,
  WidthProps,
  maxWidth,
  MaxWidthProps,
  textAlign,
  TextAlignProps,
  style,
  ColorStyleProps,
  BackgroundColorProps
} from 'styled-system'
import isPropValid from '@emotion/is-prop-valid'

const whiteSpace = style({
  prop: 'whiteSpace',
  cssProperty: 'whiteSpace',
  key: 'whiteSpace'
})

export interface BaseProps {
  as?: string
  whiteSpace?: string
}

export type BoxProps = BaseProps &
  AlignSelfProps &
  BackgroundColorProps &
  ColorStyleProps &
  HeightProps &
  FlexFnProps &
  FontSizeProps &
  MaxWidthProps &
  OrderProps &
  SpaceProps &
  TextAlignProps &
  WidthProps 
  
export const Box: React.FC<BoxProps> = styled('div', {
  shouldForwardProp: (prop: string) =>
    isPropValid(prop) && prop !== 'height' && prop !== 'width'
})(
  {
    boxSizing: 'border-box',
    label: 'Box'
  },
  alignSelf,
  color,
  height,
  flex,
  fontSize,
  maxWidth,
  order,
  space,
  textAlign,
  width,
  whiteSpace
) as React.FC<BoxProps>
Box.displayName = 'Box'
